import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { ROUTES } from 'routes/config';
import { MainLogo } from 'components/common/MainLogo';

import { HEADER_LINKS } from '../constants';
import { useHandleOpenHeader, useScrollToAnchor } from '../hooks';
import { ModalContactFormWrapper } from 'components/common/ContactUs';
import 'assets/styles/header.scss';

const MenuOfServices = ({ item }) => {
  return (
    <ul className="inner-list">
      {item.linksList.map((link) => (
        <li key={link.displayedName}>
          <Link to={link.link} state={link.state}>
            {link.displayedName}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const Navigation = ({ isOpen, isServicesOpen, toggleServicesMenu, isAnchorLink }) => {
  return (
    <nav role="navigation" className={`${isOpen ? 'open' : ''}`}>
      <ul>
        {HEADER_LINKS.map((item) =>
          item.linksList ? (
            <li key={item.link} className={`inner-list__wrapper${isServicesOpen ? ' open' : ''}`}>
              <NavLink to={item.link} className="menu-link-desktop">
                {item.displayedName}
              </NavLink>
              <button
                type="button"
                className="menu-link-btn"
                onClick={toggleServicesMenu}
                aria-expanded={isServicesOpen ? 'true' : 'false'}
                aria-label={isServicesOpen ? 'Hide Services' : 'Show Services'}
                title={isServicesOpen ? 'Hide Services' : 'Show Services'}
              />
              <MenuOfServices item={item} />
            </li>
          ) : item.anchorLink ? (
            // TODO: use NavLink
            <li key={item.link}>
              <Link to={item.link} state={item.state}>
                {item.displayedName}
              </Link>
            </li>
          ) : (
            <li key={item.link}>
              <NavLink to={item.link}>{item.displayedName}</NavLink>
            </li>
          ),
        )}
      </ul>
      <div>
        {isAnchorLink ? (
          <Link to="#contact-us" state={{ anchor: 'contact-us' }} className="btn-primary">
            Get in touch
          </Link>
        ) : (
          <ModalContactFormWrapper buttonText=" Get in touch" />
        )}
      </div>
    </nav>
  );
};

const Header = () => {
  const {
    toggleMenu,
    toggleServicesMenu,
    whiteBg,
    isOpen,
    isServicesOpen,
    isAnchorLink,
    headerContainerRef,
  } = useHandleOpenHeader();

  useScrollToAnchor();

  return (
    <header className={`header-visible ${whiteBg ? 'white-bg' : ''}`}>
      <div className="header__container" ref={headerContainerRef}>
        <NavLink to={ROUTES.root} className="header-logo" title="Landing">
          <MainLogo />
        </NavLink>
        <button
          className={`btn-hmb${isOpen ? ' open' : ''}`}
          onClick={toggleMenu}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-label={isOpen ? 'Hide navigation' : 'Show navigation'}
          title={isOpen ? 'Hide navigation' : 'Show navigation'}
        >
          <span /> <span /> <span /> <span />
        </button>
        <Navigation
          isServicesOpen={isServicesOpen}
          isOpen={isOpen}
          toggleServicesMenu={toggleServicesMenu}
          isAnchorLink={isAnchorLink}
        />
      </div>
    </header>
  );
};

export default Header;
