import * as yup from 'yup';

const thisFieldIsRequired = 'This field is required';

const firstName = yup
  .string()
  .trim()
  .required(thisFieldIsRequired)
  .max(100, 'Maximum is 100 characters');
const lastName = yup
  .string()
  .trim()
  .required(thisFieldIsRequired)
  .max(100, 'Maximum is 100 characters');
const phoneNumber = yup.string().required(thisFieldIsRequired).min(5, thisFieldIsRequired);
const email = yup.string().trim().required(thisFieldIsRequired).email('Invalid email');
const message = yup
  .string()
  .trim()
  .required(thisFieldIsRequired)
  .max(500, 'Maximum is 500 characters');
const privacyPolicy = yup
  .bool(true)
  .required(thisFieldIsRequired)
  .oneOf([true], 'The terms and conditions must be accepted.');

export const contactUsSchema = yup.object().shape({
  firstName,
  lastName,
  phoneNumber,
  message,
  email,
  privacyPolicy,
});
