import React from 'react';

import HeadingWrapper from './HeadingWrapper';
import HeadingText from './HeadingText';
import HeadingLogo from './HeadingLogo';

const Heading = () => {
  return (
    <HeadingWrapper>
      <HeadingText />
      <HeadingLogo />
    </HeadingWrapper>
  );
};

export default Heading;
