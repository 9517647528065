import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/ui/Image';
import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { HeaderSection } from 'components/common/ContentHeaderSection';
import { mapAddressLink } from 'components/layout/constants';
import { MAX_WIDTH_CONTENT } from '@constants';

import { ModalContactFormWrapper } from '../ContactUs';

const JoinUsSection = ({ wrapperProps, innerWrapperProps }) => {
  return (
    <Box maxWidth={MAX_WIDTH_CONTENT} mx="auto" overflow="hidden" {...wrapperProps} id="contact-us">
      <Box
        display="flex"
        p={{ mobile: 2.5, tablet: 7.5, laptop: '7%' }}
        flexDirection="column"
        backgroundColor="secondary.main"
        {...innerWrapperProps}
      >
        <Box
          display="flex"
          gap={{ mobile: 3.75, tablet: 7.5, laptop: 0 }}
          justifyContent="space-between"
          flexDirection={{ mobile: 'column', laptop: 'row' }}
          maxWidth={MAX_WIDTH_CONTENT}
        >
          <Box
            display="flex"
            flexDirection="column"
            maxWidth={{ mobile: 1, laptop: '50%' }}
            pr={{ laptop: '10%' }}
          >
            <HeaderSection title="Join Us on the Journey" subTitle="Contact us" />
            <Typography medium color="text.simple" mt={6.25}>
              Join a community that prioritizes individual growth, values diverse talents and
              fosters an environment conducive to achieving collective goals. Explore our website to
              learn more about our services, meet our team and discover how we can help you unlock
              your full potential.
            </Typography>
            <Box display="flex" gap={2.5} mt={{ mobile: 3.75, tablet: 6.25 }}>
              <ModalContactFormWrapper />
              <a href={mapAddressLink} target="_blank" rel="noopener noreferrer">
                <Button buttonTypes="outlined" size="fit-content">
                  Map
                </Button>
              </a>
            </Box>
          </Box>
          <Image
            src={require('assets/images/join-us.png')}
            style={{ maxHeight: 623, minWidth: 320 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(JoinUsSection);

JoinUsSection.propTypes = {
  wrapperProps: PropTypes.object,
  innerWrapperProps: PropTypes.object,
};
