import React from 'react';

import Box from 'components/ui/Box';

import ServiceBlockImage from './ServiceBlockImage';
import ServiceBlockText from './ServiceBlockText';
import { MAX_WIDTH_SERVICE_CONTENT, serviceContent } from '../constants';

const ServiceBlock = () => {
  return (
    <Box display="flex" flexDirection="column">
      {serviceContent.map((service) => (
        <Box
          key={service.id}
          id={service.id}
          width={1}
          maxWidth={MAX_WIDTH_SERVICE_CONTENT}
          mx="auto"
          pt={{ mobile: 10, tablet: 12 }}
        >
          <Box
            display="flex"
            backgroundColor="gray.dark"
            height={{ mobile: 'auto', laptop: 650 }}
            borderRadius={2}
            width={1}
          >
            <Box display="flex" flexDirection={{ mobile: 'column', laptop: service.direction }}>
              <ServiceBlockImage image={service.image} />
              <ServiceBlockText
                text={service.text}
                title={service.title}
                textSubtitle={service.textSubtitle}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ServiceBlock;
