import React, { memo } from 'react';
import MuiTypography from '@mui/material/Typography';

import { Fonts } from 'assets/theme';

const Typography = React.forwardRef((props, ref) => {
  const { medium, semiBold, extraBold, ...other } = props;
  return (
    <MuiTypography
      ref={ref}
      fontSize="inherit"
      color="inherit"
      {...(medium && { fontFamily: Fonts.Medium })}
      {...(semiBold && { fontFamily: Fonts.SemiBold })}
      {...(extraBold && { fontFamily: Fonts.ExtraBold })}
      {...other}
    />
  );
});

export default memo(Typography);
