import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Layout from 'components/layout';
import Landing from 'pages/Landing';
import People from 'pages/People';
import Vision from 'pages/Vision';
import Services from 'pages/Services';
import Industry from 'pages/Industry';
import Privacy from 'pages/Privacy';
import NoMatch from 'pages/NoMatch';

import { ROUTES } from './config';

const RoutesList = () => (
  <Router>
    <Layout>
      <Routes>
        <Route exact path={ROUTES.root} element={<Landing />} />
        <Route exact path={ROUTES.vision} element={<Vision />} />
        <Route exact path={ROUTES.industry} element={<Industry />} />
        <Route exact path={ROUTES.services} element={<Services />} />
        <Route exact path={ROUTES.people} element={<People />} />
        <Route exact path={ROUTES.privacy} element={<Privacy />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Layout>
  </Router>
);

export default RoutesList;
