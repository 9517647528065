import React from 'react';

import ToastContainer from 'components/common/ToastContainer';

import RoutesList from 'routes';
import ThemeProvider from 'assets/theme';

const App = () => (
  <ThemeProvider>
    <RoutesList />
    <ToastContainer />
  </ThemeProvider>
);

export default App;
