import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'routes';
import useHandleScroll from './useHandleScroll';

const useHandleOpenHeader = () => {
  const { isWhiteBg } = useHandleScroll();
  const location = useLocation();

  const headerContainerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isAnchorLink, setAnchorLink] = useState(true);

  // close the menu on resize a window
  useEffect(() => {
    const onResize = () => {
      let currentWindowWidth = window.innerWidth;

      if (currentWindowWidth > 1024 && isOpen) {
        setIsOpen(false);
        isServicesOpen && setServicesOpen(false);
      }
    };

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  // freeze the header if the menu is open
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add('overflow-hidden');
    } else body.classList.remove('overflow-hidden');
  }, [isOpen]);

  // close menu on click outside the header
  useEffect(() => {
    const onClickOutside = (e) => {
      if (headerContainerRef.current && !headerContainerRef.current.contains(e.target)) {
        isOpen && setIsOpen(false);
        isServicesOpen && setServicesOpen(false);
      }
    };

    window.addEventListener('click', onClickOutside);
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  });

  // close mobile menu and services menu on change a route
  useEffect(() => {
    if (location) {
      isServicesOpen && setServicesOpen(false);
      isOpen && setIsOpen(false);

      if (location.pathname === ROUTES.privacy) {
        setAnchorLink(false);
      } else if (!isAnchorLink) {
        setAnchorLink(true);
      }
    }
  }, [location]);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const toggleServicesMenu = useCallback(() => {
    setServicesOpen(!isServicesOpen);
  }, [isServicesOpen]);

  return useMemo(
    () => ({
      whiteBg: isWhiteBg || isOpen || isServicesOpen,
      isOpen,
      isServicesOpen,
      isAnchorLink,
      toggleMenu,
      toggleServicesMenu,
      headerContainerRef,
    }),
    [toggleMenu, toggleServicesMenu, isWhiteBg, isOpen, isServicesOpen, isAnchorLink],
  );
};

export default useHandleOpenHeader;
