import React from 'react';
import PropTypes from 'prop-types';

import Collapse from '../Collapse';

const When = React.forwardRef((props, ref) => {
  const { condition, fallback, children, disabledWrapper, ...other } = props;

  if (disabledWrapper) return <>{condition ? children : fallback}</>;

  return (
    <>
      <Collapse ref={ref} unmountOnExit timeout={0} {...other} in={condition}>
        {children}
      </Collapse>
      <Collapse ref={ref} unmountOnExit timeout={0} {...other} in={!condition}>
        {fallback}
      </Collapse>
    </>
  );
});

export default React.memo(When);

When.propTypes = {
  condition: PropTypes.bool.isRequired,
  fallback: PropTypes.node,
  children: PropTypes.node,
  disabledWrapper: PropTypes.bool,
};
