import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

const TestimonialsHeading = () => {
  return (
    <Box position="relative" px={{ mobile: 2, tablet: 7.5, laptop: '7%' }} id="testimonials">
      <Box maxWidth="592px">
        <Typography medium color="primary.main">
          Clients about us
        </Typography>
        <Typography
          variant="h2"
          extraBold
          fontSize={{ mobile: 32, tablet: 56 }}
          lineHeight={{ mobile: '40px', tablet: '64px' }}
          mt={2.5}
        >
          We are proud of how our customers talk about us
        </Typography>
        <Typography medium color="text.simple" py={{ mobile: 2, tablet: 6.5, laptop: 4 }}>
          Their experiences motivate us to strive for excellence in our daily work
        </Typography>
      </Box>
    </Box>
  );
};

export default TestimonialsHeading;
