import React from 'react';

import Typography from 'components/ui/Typography';

export const VISION = [
  {
    title: 'Your Trusted People Advisor',
    subtitle: 'Our mission',
    text: (
      <Typography medium color="text.simple" mt={6.25}>
        HCC's mission is to become Your Trusted People Advisor by providing a unique blend of Career
        Advisory, Leadership Development and Executive Search Services.
        <br />
        <br />
        Partner with us to unlock the full potential of your Leadership Team and propel your
        organization toward unparalleled success
      </Typography>
    ),
    image: require('assets/images/our-vision.jpg'),
  },
  {
    title: 'Tailored learning experiences',
    subtitle: 'Our way',
    text: (
      <Typography medium color="text.simple" mt={6.25}>
        We trust that sustainable leadership development is the ongoing process of nurturing
        effective leaders.
        <br />
        <br />
        That is why we invest so much effort in designing tailored learning experiences and
        customized programs that cater to the specific needs and challenges of both individual
        leaders and organizations.
      </Typography>
    ),
    image: require('assets/images/career-advisory.png'),
  },
];
