import React from 'react';

import Box from 'components/ui/Box';
import { JoinUsSection } from 'components/common/JoinUsSection';

import {
  IndustriesSection,
  OurServices,
  OurVision,
  PartnerToUs,
  OurPeople,
  OurValues,
  Heading,
  Testimonials,
} from './components';

import { useScrollToTop } from 'components/layout/hooks';

const Landing = () => {
  useScrollToTop();

  return (
    <Box>
      <Heading />
      <OurVision />
      <PartnerToUs />
      <OurServices />
      <IndustriesSection />
      <Testimonials />
      <OurPeople />
      <OurValues />
      <JoinUsSection />
    </Box>
  );
};

export default Landing;
