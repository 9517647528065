import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/ui/Image';
import Box from 'components/ui/Box';

const ServiceBlockImage = ({ imageWrapperProps, ImageProps, image }) => {
  return (
    <Box
      p={{ mobile: 2.5, laptop: 2.5 }}
      height={1}
      minWidth={{ mobile: 220, tablet: 480 }}
      {...imageWrapperProps}
    >
      <Image src={image} alt="service" sx={{ maxWidth: 584, maxHeight: 610 }} {...ImageProps} />
    </Box>
  );
};

export default React.memo(ServiceBlockImage);

ServiceBlockImage.propTypes = {
  imageWrapperProps: PropTypes.object,
  ImageProps: PropTypes.object,
  image: PropTypes.string.isRequired,
};
