import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { ModalContactFormWrapper } from 'components/common/ContactUs';

const HeadingText = ({ title, text, buttonText, titleWidth }) => {
  return (
    <Box
      color="secondary.main"
      width="100%"
      textAlign="left"
      pt={11}
      px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
    >
      <Box display="inline-flex" flex-direction="column" justify-content="space-between">
        <Box pb={{ mobile: 3, tablet: 7.5 }}>
          <Typography
            variant="h1"
            extraBold
            fontSize={{ mobile: '32px', tablet: '80px' }}
            lineHeight={1.14}
            maxWidth={titleWidth ?? 1}
          >
            {title}
          </Typography>
          <Typography
            width={{ laptop: '622px' }}
            pb={{ mobile: 3, tablet: 6 }}
            pt={{ mobile: 1.5, tablet: 3 }}
            fontFamily="ManropeMedium"
            fontSize={{ mobile: '16px', tablet: '20px' }}
            lineHeight={1.6}
            sx={{
              '& a': { color: 'primary.main', fontSize: 'inherit' },
              '& a:hover': { color: 'primary.hover' },
            }}
          >
            {text}
          </Typography>
          <ModalContactFormWrapper buttonText={buttonText ?? "Let's grow together!"} />
        </Box>
      </Box>
    </Box>
  );
};

export default HeadingText;
