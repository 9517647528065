import { Fonts } from 'assets/theme';

export default function injectStyle(theme) {
  const style = `:root {
      --toastify-color-light: #fff;
      --toastify-color-dark: #121212;
      --toastify-color-info: ${theme.palette.primary.main};
      --toastify-color-success: ${theme.palette.primary.main};
      --toastify-color-warning: #f1c40f;
      --toastify-color-error: ${theme.palette.error.main};
      --toastify-color-transparent: rgba(255, 255, 255, 0.7);
      --toastify-icon-color-info: var(--toastify-color-info);
      --toastify-icon-color-success: var(--toastify-color-success);
      --toastify-icon-color-warning: var(--toastify-color-warning);
      --toastify-icon-color-error: var(--toastify-color-error);
      --toastify-toast-width: 440px;
      --toastify-toast-background: #fff;
      --toastify-toast-min-height: 48px;
      --toastify-toast-max-height: 800px;
      --toastify-font-family: ${Fonts.SemiBold}, sans-serif;
      --toastify-z-index: 9999;
      --toastify-text-color-light: #757575;
      --toastify-text-color-dark: #fff;
      --toastify-text-color-info: #fff;
      --toastify-text-color-success: #fff;
      --toastify-text-color-warning: #fff;
      --toastify-text-color-error: #fff;
      --toastify-spinner-color: #616161;
      --toastify-spinner-color-empty-area: #e0e0e0;
      --toastify-color-progress-light: linear-gradient(
        to right,
        #4cd964,
        #5ac8fa,
        #007aff,
        #34aadc,
        #5856d6,
        #ff2d55
      );
      --toastify-color-progress-dark: #bb86fc;
      --toastify-color-progress-info: var(--toastify-color-info);
      --toastify-color-progress-success: var(--toastify-color-success);
      --toastify-color-progress-warning: var(--toastify-color-warning);
      --toastify-color-progress-error: var(--toastify-color-error);
    }
    .Toastify__toast-container {
      font-family: ${Fonts.Regular};
    }
    .Toastify__toast-container--bottom-left {
      bottom: 3em;
    }
    .Toastify__toast {
      box-shadow: none;
      margin-bottom: 0;
      margin-top: 1rem;
    }
    .Toastify__toast-body-custom > div:first-child {
      font-family: ${Fonts.Regular};
    }
    `;
  const css = document.createElement('style');
  css.innerText = style;
  document.head.appendChild(css);
}
