import React, { Fragment, useCallback, useMemo, useState } from 'react';

import Box from 'components/ui/Box';
import Collapse from 'components/ui/Collapse';
import { useIsTabletResolution } from 'hooks';

import OurServiceImage from './OurServiceImage';
import { CollapsedTextWrapper, CollapsedText, TextTitle } from './OurServiceText';
import { OUR_SERVICES, OurServicesTextVariations } from '../constants';

const OurServiceBody = () => {
  const [openedService, setOpenedService] = useState(OurServicesTextVariations.executive);
  const isTabletResolution = useIsTabletResolution();

  const changeText = useCallback((variant) => () => setOpenedService(variant), []);

  return useMemo(
    () => (
      <Box
        display="flex"
        backgroundColor="gray.dark"
        height={{ mobile: 'auto', laptop: 650 }}
        borderRadius={2}
        mt={6}
        mx="auto"
        width={1}
      >
        <Box display="flex" flexDirection={{ mobile: 'column', laptop: 'row' }}>
          <OurServiceImage openedService={openedService} condition={!isTabletResolution} />
          <Box
            display="flex"
            flexDirection="column"
            pr={2.5}
            maxWidth={{ mobile: 1, laptop: 592 }}
            sx={{ overflowY: 'hidden' }}
          >
            {OUR_SERVICES.map((section) => (
              <Fragment key={section.id}>
                <CollapsedTextWrapper
                  condition={openedService === OurServicesTextVariations[section.type]}
                >
                  <TextTitle
                    title={section.title}
                    changeText={changeText}
                    textType={OurServicesTextVariations[section.type]}
                    openedService={openedService}
                  />
                  <CollapsedText
                    condition={openedService === OurServicesTextVariations[section.type]}
                    text={section.text}
                    subTitle={section.subTitle}
                    openedService={openedService}
                  />
                </CollapsedTextWrapper>
                <Collapse in={section.id !== 3}>
                  <Box borderTop={1} borderColor="gray.contrastText" />
                </Collapse>
              </Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    ),
    [openedService, changeText, isTabletResolution],
  );
};

export default OurServiceBody;
