import React from 'react';

import Image from 'components/ui/Image';
import Box from 'components/ui/Box';
import { HeaderSection } from 'components/common/ContentHeaderSection';

import SectionWrapper from 'pages/Landing/components/SectionWrapper';
import { VISION } from '../constants';

const OurMission = () => (
  <SectionWrapper>
    {VISION.map((item) => (
      <Box
        key={item.title}
        display="flex"
        gap={{ mobile: 3.75, tablet: 7.5, laptop: '7%' }}
        justifyContent="space-between"
        flexDirection={{ mobile: 'column', laptop: 'row' }}
        sx={{
          '&:nth-of-type(2n)': {
            flexDirection: { mobile: 'column', laptop: 'row-reverse' },
            paddingTop: { mobile: 3.75, tablet: 7.5, laptop: '7%' },
          },
        }}
      >
        <Box display="flex" flexDirection="column" maxWidth={{ mobile: 1, laptop: 490 }}>
          <HeaderSection title={item.title} subTitle={item.subtitle} />
          {item.text}
        </Box>
        <Image src={item.image} style={{ maxHeight: 662, minWidth: 320 }} fit="contain" />
      </Box>
    ))}
  </SectionWrapper>
);

export default OurMission;
