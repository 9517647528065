import React from 'react';

import Box from 'components/ui/Box';
import { JoinUsSection } from 'components/common/JoinUsSection';
import { PageHeading } from 'components/common/PageHeading';

import { IndustriesSection } from './components';
import { useScrollToTop } from 'components/layout/hooks';

const Industry = () => {
  useScrollToTop();

  return (
    <Box>
      <PageHeading
        title="Transform to thrive"
        text="Elevate Your Leadership leveraging our sector relevant expertise."
      />
      <IndustriesSection />
      <JoinUsSection />
    </Box>
  );
};

export default Industry;
