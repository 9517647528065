import React from 'react';

import Box from 'components/ui/Box';
import { MAX_WIDTH_CONTENT } from '@constants';

const PeopleSectionWrapper = (props) => {
  return (
    <Box mx="auto" maxWidth={MAX_WIDTH_CONTENT} py={{ mobile: 2.5, tablet: 7 }}>
      <Box px={{ mobile: 2.5, tablet: 7.5, laptop: '7%' }} {...props} />
    </Box>
  );
};

export default PeopleSectionWrapper;
