import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { HeaderSection } from 'components/common/ContentHeaderSection';

const PageHeaderSection = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Box display="flex" flexDirection="column" maxWidth={590} gap={6.25}>
        <HeaderSection
          title="HCC Executive redefines leadership"
          subTitle="Our services"
          subTitleProps={{ textAlign: 'center' }}
          titleProps={{ textAlign: 'center' }}
        />
        <Typography textAlign="center">
          We are here to guide you through your career journey by offering tailored advice that
          aligns with your skills and aspirations, ensuring optimal growth as you navigate your
          professional path.
        </Typography>
      </Box>
    </Box>
  );
};

export default PageHeaderSection;
