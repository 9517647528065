import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Icon from 'components/ui/Icon';

import { IndustriesInfo } from '../constants';

const IndustriesList = () => {
  return (
    <>
      {IndustriesInfo.map((industry) => (
        <Box
          className="keen-slider__slide"
          key={industry.title}
          width={280}
          height={280}
          borderRadius={2}
          p={3.75}
          backgroundColor="secondary.contrastText"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          position="relative"
          sx={{
            transition: (theme) => theme.transitions.create('background-color', { duration: 300 }),
            '&:hover': {
              backgroundColor: 'primary.main',
              '.industry-text': { opacity: 1 },
              '& .icon': { opacity: 0 },
            },
          }}
        >
          <Typography color="secondary.main" fontSize={24} lineHeight="32px" extraBold>
            {industry.title}
          </Typography>
          <Typography
            className="industry-text"
            color="secondary.main"
            semiBold
            sx={{
              transition: (theme) => theme.transitions.create('opacity', { duration: 300 }),
              opacity: 0,
            }}
          >
            {industry.text}
          </Typography>
          <Icon
            name={industry.iconName}
            position="absolute"
            bottom={30}
            width="fit-content"
            fontSize={55}
            height={55}
            sx={{
              transition: (theme) => theme.transitions.create('opacity', { duration: 300 }),
              opacity: 1,
            }}
          />
        </Box>
      ))}{' '}
    </>
  );
};

export default IndustriesList;
