import React, { Fragment } from 'react';

import Box from 'components/ui/Box';

const CarouselDots = ({ instanceRef, currentSlide, slidesCount }) => (
  <Box className="dots">
    {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx, index, array) => {
      return index <= array.length - slidesCount ? (
        <button
          key={index}
          onClick={() => {
            instanceRef.current?.moveToIdx(idx);
          }}
          className={'dot' + (currentSlide === idx ? ' active' : '')}
        ></button>
      ) : (
        <Fragment key={index} />
      );
    })}
  </Box>
);
export default CarouselDots;
