import React from 'react';

import Image from 'components/ui/Image';
import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { HeaderSection } from 'components/common/ContentHeaderSection';
import { ROUTES } from 'routes';

const OurVisionBody = () => {
  return (
    <Box
      display="flex"
      gap={{ mobile: 3.75, tablet: 7.5, laptop: 0 }}
      justifyContent="space-between"
      flexDirection={{ mobile: 'column', laptop: 'row' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={{ mobile: 1, laptop: '50%' }}
        pr={{ laptop: '7%' }}
      >
        <HeaderSection
          title="Transformative power of leadership development"
          subTitle="Our vision"
        />
        <Typography medium color="text.simple" mt={6.25}>
          We believe in shaping leaders who not only meet the demands of today's dynamic business
          landscape but also drive future success.
          <br />
          <br />
          Our comprehensive approach ensures that your organization not only attracts top-tier
          talent but also cultivates and nurtures leadership capabilities at every level.
          <br />
          <br />
          HCC's portfolio of services combine precision of executive search with the guidance of
          career advisory and the transformative power of leadership development.
        </Typography>
        <Button
          to={ROUTES.vision}
          buttonTypes="link"
          size="fit-content"
          sx={{ mt: { mobile: 3.75, tablet: 6.25 } }}
        >
          Find out more
        </Button>
      </Box>
      <Image
        src={require('assets/images/our-vision.jpg')}
        style={{ maxHeight: 662, minWidth: 320 }}
        wrapperStyle={{ height: 'auto' }}
      />
    </Box>
  );
};

export default OurVisionBody;
