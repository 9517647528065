import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from 'components/ui/Box';
import Button from 'components/ui/Button';

import FormPhoneInput from './FormPhoneInput';
import TextFieldForm from './TextFieldForm';
import AgreePrivacyPolicy from './AgreePrivacyPolicy';
import { contactUsSchema } from './validation';

import { sendContactUsForm } from '../helpers';

import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';

const ContactForm = ({ closeModal }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactUsSchema) });

  const submit = useCallback(
    (data) => {
      sendContactUsForm(data);
      closeModal();
    },
    [closeModal],
  );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box display="flex" flexDirection="column" mt={4} gap={3}>
        <Box display="flex" width={1} gap={3} flexWrap={{ mobile: 'wrap', laptop: 'nowrap' }}>
          <TextFieldForm
            name="firstName"
            label="First name"
            placeholder="First name"
            control={control}
            errors={errors?.firstName}
          />
          <TextFieldForm
            name="lastName"
            label="Last name"
            placeholder="Last name"
            control={control}
            errors={errors?.lastName}
          />
        </Box>
        <Box display="flex" gap={3} flexWrap={{ mobile: 'wrap', laptop: 'nowrap' }}>
          <TextFieldForm
            name="email"
            label="Email"
            placeholder="you@company.com"
            control={control}
            errors={errors?.email}
          />
          <FormPhoneInput name="phoneNumber" control={control} error={errors?.phoneNumber} />
        </Box>
        <TextFieldForm
          name="message"
          label="Message"
          placeholder="Leave us a message..."
          multiline
          control={control}
          errors={errors?.message}
        />
        <AgreePrivacyPolicy control={control} name="privacyPolicy" error={errors?.privacyPolicy} />
        <Box display="flex" flexDirection="column" gap={1}>
          <Button onClick={closeModal} buttonTypes="text">
            Cancel
          </Button>
          <Button type="submit">Send message</Button>
        </Box>
      </Box>
    </form>
  );
};

export default ContactForm;

ContactForm.propTypes = {
  closeModal: PropTypes.func,
};
