import React from 'react';
import MuiList from '@mui/material/List';

const List = React.forwardRef((props, ref) => {
  const { sx } = props;
  return (
    <MuiList
      sx={{
        listStyle: 'disc',
        paddingTop: 0,
        paddingBottom: 2,
        paddingLeft: 3,
        ...sx,
      }}
      ref={ref}
      {...props}
    />
  );
});

export default React.memo(List);
