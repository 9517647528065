import React from 'react';

import SectionWrapper from './SectionWrapper';
import OurValueBody from './OurValueBody';

const OurValues = () => {
  return (
    <SectionWrapper backgroundColor="secondary.dark" py={0} px={0} wrapper="true">
      <OurValueBody />
    </SectionWrapper>
  );
};

export default OurValues;
