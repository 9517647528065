import { useMediaQuery } from '@mui/material';

import { MIN_SIZE_DESKTOP_RESOLUTION } from '@constants';

const useIsTabletResolution = () => {
  const tabletResolution = useMediaQuery(`(max-width: ${MIN_SIZE_DESKTOP_RESOLUTION}px)`);
  return tabletResolution;
};

export default useIsTabletResolution;
