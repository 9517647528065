import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import IconButton from 'components/ui/IconButton';
import Collapse from 'components/ui/Collapse';
import { useIsTabletResolution } from 'hooks';

import OurServiceImage from './OurServiceImage';
import { ModalContactFormWrapper } from 'components/common/ContactUs';

export const TextTitle = React.memo(({ title, changeText, textType, openedService }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      pr={1.5}
      pt={3}
      pl={3}
      pb={3}
    >
      <Typography fontSize={24} lineHeight="32px" extraBold>
        {title}
      </Typography>
      <IconButton
        iconName={openedService === textType ? 'minus' : 'plus'}
        onClick={changeText(textType)}
        IconProps={{
          sx: {
            '&:before': { color: 'secondary.dark', fontSize: openedService === textType ? 24 : 32 },
          },
        }}
      />
    </Box>
  );
});

export const CollapsedText = React.memo(({ text, subTitle, condition, openedService }) => {
  const isTabletResolution = useIsTabletResolution();
  return (
    <Box display="flex" height={1}>
      <Collapse in={condition} sx={{ '& .MuiCollapse-wrapper': { height: 1 } }}>
        <Box
          display="flex"
          height={1}
          flexDirection="column"
          justifyContent="space-between"
          gap={{ mobile: 7.5, laptop: 0 }}
        >
          <Typography pr={1.25} pl={3}>
            {subTitle}
            <br />
            <br />
            {text}
          </Typography>
          <Box ml={3} pb={3.75} display="flex" flexDirection="column">
            <ModalContactFormWrapper
              buttonProps={{ buttonTypes: 'link', size: 'fit-content' }}
              buttonText="Let’s grow together"
            />
            <OurServiceImage openedService={openedService} condition={isTabletResolution} />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
});

export const CollapsedTextWrapper = React.memo(({ condition, ...other }) => {
  return <Box display="flex" flexDirection="column" flex={condition ? 1 : 0} {...other} />;
});

TextTitle.propTypes = {
  title: PropTypes.string,
  changeText: PropTypes.func,
  textType: PropTypes.string,
  openedService: PropTypes.string,
};

CollapsedText.propTypes = {
  text: PropTypes.string,
  subTitle: PropTypes.string,
  condition: PropTypes.bool,
  openedService: PropTypes.string,
};

CollapsedTextWrapper.propTypes = {
  condition: PropTypes.bool,
};
