import React from 'react';

import Box from 'components/ui/Box';
import { PageHeading } from 'components/common/PageHeading';
import { JoinUsSection } from 'components/common/JoinUsSection';
import { OurValues } from './components';
import { OurMission } from './components';

import { useScrollToTop } from 'components/layout/hooks';

const Vision = () => {
  useScrollToTop();

  return (
    <Box>
      <PageHeading
        title="Created by people for people"
        text="Tailored learning experience & customized leadership development programs."
        titleWidth={{ mobile: '300px', tablet: '800px' }}
      />
      <OurMission />
      <OurValues />
      <JoinUsSection />
    </Box>
  );
};

export default Vision;
