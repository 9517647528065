import { useEffect, useMemo, useState } from 'react';

const useHandleScroll = () => {
  const [isWhiteBg, setWhiteBg] = useState(false);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    if (prevScrollPos > 20 && !isWhiteBg) {
      setWhiteBg(true);
    }

    const onScroll = () => {
      let currentScrollPos = window.pageYOffset;

      if (currentScrollPos > 20 && !isWhiteBg) {
        setWhiteBg(true);
      }
      if (currentScrollPos <= 20) {
        setWhiteBg(false);
      }

      prevScrollPos = currentScrollPos;
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isWhiteBg]);

  return useMemo(() => ({ isWhiteBg }), [isWhiteBg]);
};

export default useHandleScroll;
