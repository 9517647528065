import React from 'react';
import MuiIconButton from '@mui/material/IconButton';

import Icon from 'components/ui/Icon';

const IconButton = React.forwardRef((props, ref) => {
  const { disablePadding, color = 'inherit', iconName, IconProps, sx, children, ...other } = props;

  return (
    <MuiIconButton
      sx={{
        width: 48,
        position: 'relative',
        zIndex: 2,
        '&:hover': { backgroundColor: 'gray.light' },
        '&:active': { backgroundColor: 'gray.dark' },
        ...sx,
      }}
      ref={ref}
      color={color}
      disableRipple
      aria-label={`button icon ${iconName}`}
      {...other}
    >
      <Icon name={iconName} borderRadius="inherit" {...IconProps}>
        {children}
      </Icon>
    </MuiIconButton>
  );
});

export default React.memo(IconButton);
