import React from 'react';

const MainLogo = () => (
  <svg width="195" height="32" viewBox="0 0 195 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M91.0904 26.4897L88.3244 23.6445C88.0571 23.3781 87.5671 23.3349 87.2096 23.6445C85.3375 25.1572 83.0629 26.1784 80.387 26.1784C74.6778 26.1784 70.3976 21.466 70.3976 15.9126C70.3976 10.3107 74.6328 5.5552 80.3437 5.5552C82.8393 5.5552 85.3375 6.53281 87.2096 8.17714C87.5671 8.53339 88.0134 8.53339 88.3244 8.17714L91.0453 5.37862C91.4015 5.02236 91.4015 4.48908 91.0016 4.13282C87.9683 1.42238 84.7127 0 80.2085 0C71.2884 0 64.1087 7.15593 64.1087 16.0443C64.1087 24.9339 71.2884 32 80.2085 32C84.1776 32 88.0134 30.6239 91.0453 27.6893C91.3577 27.3793 91.4466 26.7997 91.0904 26.4897ZM61.1218 26.4897L58.3558 23.6445C58.0885 23.3781 57.5972 23.3349 57.241 23.6445C55.3676 25.1572 53.0942 26.1784 50.4184 26.1784C44.7092 26.1784 40.429 21.466 40.429 15.9126C40.429 10.3107 44.6642 5.5552 50.3733 5.5552C52.8707 5.5552 55.3676 6.53281 57.241 8.17714C57.5972 8.53339 58.0448 8.53339 58.3558 8.17714L61.0767 5.37862C61.4329 5.02236 61.4329 4.48908 61.0317 4.13282C57.9997 1.42238 54.7441 0 50.2381 0C41.3198 0 34.1397 7.15593 34.1397 16.0443C34.1397 24.9339 41.3198 32 50.2381 32C54.209 32 58.0448 30.6239 61.0767 27.6893C61.3891 27.3793 61.4779 26.7997 61.1218 26.4897ZM27.5397 0.443009C27.986 0.443009 28.3872 0.844622 28.3872 1.28763V30.7124C28.3872 31.1571 27.986 31.557 27.5397 31.557H23.4367C22.9471 31.557 22.5892 31.1571 22.5892 30.7124V18.446H8.05187V30.7124C8.05187 31.1571 7.69441 31.557 7.20484 31.557H3.05673C2.61046 31.557 2.20926 31.1571 2.20926 30.7124V3.24945L0 0.443009H7.20484C7.69441 0.443009 8.05187 0.844622 8.05187 1.28763V13.0661H22.5892V3.30538L20.3349 0.443009H27.5397Z"
      fill="#FFFFFF"
    />
    <path
      d="M104.478 1.77052C104.478 1.59482 104.619 1.43717 104.813 1.43717H111.976C112.17 1.43717 112.31 1.59482 112.31 1.77052V3.20876C112.31 3.38446 112.17 3.54211 111.976 3.54211H106.766V6.41902H111.114C111.289 6.41902 111.448 6.57667 111.448 6.75237V8.20822C111.448 8.4011 111.289 8.54158 111.114 8.54158H106.766V11.6114H111.976C112.17 11.6114 112.31 11.769 112.31 11.9447V13.3829C112.31 13.5582 112.17 13.7163 111.976 13.7163H104.813C104.619 13.7163 104.478 13.5582 104.478 13.3829V1.77052Z"
      fill="#0BC178"
    />
    <path
      d="M117.592 7.41887L114.072 1.9284C113.932 1.70029 114.072 1.4374 114.371 1.4374H116.414C116.554 1.4374 116.642 1.52459 116.695 1.59505L118.912 5.31349H118.983L121.254 1.59505C121.289 1.52459 121.394 1.4374 121.535 1.4374H123.577C123.876 1.4374 124.017 1.70029 123.876 1.9284L120.338 7.41887L123.999 13.2251C124.14 13.4704 123.999 13.7161 123.7 13.7161H121.799C121.658 13.7161 121.535 13.6285 121.5 13.5584L118.983 9.54099H118.912L116.448 13.5584C116.395 13.6285 116.29 13.7161 116.149 13.7161H114.248C113.932 13.7161 113.791 13.4532 113.932 13.2251L117.592 7.41887Z"
      fill="#0BC178"
    />
    <path
      d="M125.956 1.77052C125.956 1.59482 126.097 1.43717 126.291 1.43717H133.454C133.648 1.43717 133.788 1.59482 133.788 1.77052V3.20876C133.788 3.38446 133.648 3.54211 133.454 3.54211H128.244V6.41902H132.592C132.768 6.41902 132.926 6.57667 132.926 6.75237V8.20822C132.926 8.4011 132.768 8.54158 132.592 8.54158H128.244V11.6114H133.454C133.648 11.6114 133.788 11.769 133.788 11.9447V13.3829C133.788 13.5582 133.648 13.7163 133.454 13.7163H126.291C126.097 13.7163 125.956 13.5582 125.956 13.3829V1.77052Z"
      fill="#0BC178"
    />
    <path
      d="M141.727 1.26165C143.504 1.26165 144.789 1.82311 145.986 2.8932C146.145 3.03367 146.145 3.24373 146.004 3.3842L144.93 4.48908C144.807 4.62956 144.63 4.62956 144.489 4.48908C143.751 3.84042 142.765 3.45466 141.779 3.45466C139.526 3.45466 137.855 5.3315 137.855 7.54169C137.855 9.73427 139.544 11.5935 141.797 11.5935C142.853 11.5935 143.751 11.1906 144.489 10.5939C144.63 10.471 144.824 10.4886 144.93 10.5939L146.021 11.7163C146.162 11.8392 146.127 12.0673 146.004 12.1897C144.807 13.3475 143.293 13.8918 141.727 13.8918C138.206 13.8918 135.373 11.1025 135.373 7.59409C135.373 4.08615 138.206 1.26165 141.727 1.26165Z"
      fill="#0BC178"
    />
    <path
      d="M148.056 1.77053C148.056 1.59482 148.214 1.43717 148.39 1.43717H150.063C150.256 1.43717 150.397 1.59482 150.397 1.77053V9.08499C150.397 10.5761 151.418 11.6114 152.949 11.6114C154.48 11.6114 155.519 10.5761 155.519 9.10304V1.77053C155.519 1.59482 155.66 1.43717 155.853 1.43717H157.525C157.701 1.43717 157.86 1.59482 157.86 1.77053V9.20829C157.86 11.8743 155.73 13.8916 152.949 13.8916C150.185 13.8916 148.056 11.8743 148.056 9.20829V1.77053Z"
      fill="#0BC178"
    />
    <path
      d="M162.767 3.54203H160.074C159.881 3.54203 159.74 3.38438 159.74 3.20868V1.77044C159.74 1.59474 159.881 1.43709 160.074 1.43709H167.766C167.959 1.43709 168.1 1.59474 168.1 1.77044V3.20868C168.1 3.38438 167.959 3.54203 167.766 3.54203H165.073V13.3829C165.073 13.5581 164.915 13.7162 164.738 13.7162H163.102C162.925 13.7162 162.767 13.5581 162.767 13.3829V3.54203Z"
      fill="#0BC178"
    />
    <path
      d="M170.029 1.77052C170.029 1.59482 170.187 1.43717 170.364 1.43717H172C172.176 1.43717 172.335 1.59482 172.335 1.77052V13.3829C172.335 13.5582 172.176 13.7163 172 13.7163H170.364C170.187 13.7163 170.029 13.5582 170.029 13.3829V1.77052Z"
      fill="#0BC178"
    />
    <path
      d="M174.062 1.89317C173.957 1.66506 174.097 1.43739 174.361 1.43739H176.156C176.315 1.43739 176.421 1.54264 176.456 1.63027L179.853 9.17284H179.941L183.337 1.63027C183.373 1.54264 183.478 1.43739 183.636 1.43739H185.432C185.696 1.43739 185.837 1.66506 185.731 1.89317L180.275 13.6989C180.223 13.8037 180.134 13.8918 179.976 13.8918H179.8C179.642 13.8918 179.554 13.8037 179.501 13.6989L174.062 1.89317Z"
      fill="#0BC178"
    />
    <path
      d="M187.168 1.77052C187.168 1.59482 187.309 1.43717 187.503 1.43717H194.666C194.859 1.43717 195 1.59482 195 1.77052V3.20876C195 3.38446 194.859 3.54211 194.666 3.54211H189.456V6.41902H193.804C193.979 6.41902 194.137 6.57667 194.137 6.75237V8.20822C194.137 8.4011 193.979 8.54158 193.804 8.54158H189.456V11.6114H194.666C194.859 11.6114 195 11.769 195 11.9447V13.3829C195 13.5582 194.859 13.7163 194.666 13.7163H187.503C187.309 13.7163 187.168 13.5582 187.168 13.3829V1.77052Z"
      fill="#0BC178"
    />
    <path
      d="M110.111 18.1484C111.888 18.1484 113.173 18.7099 114.37 19.78C114.529 19.9205 114.529 20.1305 114.388 20.271L113.313 21.3759C113.191 21.5164 113.014 21.5164 112.873 21.3759C112.135 20.7272 111.149 20.3415 110.163 20.3415C107.91 20.3415 106.239 22.2183 106.239 24.4285C106.239 26.6211 107.928 28.4803 110.181 28.4803C111.237 28.4803 112.135 28.0773 112.873 27.4807C113.014 27.3578 113.208 27.3754 113.313 27.4807L114.405 28.6031C114.546 28.726 114.51 28.9541 114.388 29.0765C113.191 30.2343 111.677 30.7785 110.111 30.7785C106.59 30.7785 103.757 27.9893 103.757 24.4809C103.757 20.9729 106.59 18.1484 110.111 18.1484Z"
      fill="#0BC178"
    />
    <path
      d="M121.945 28.498C124.163 28.498 125.993 26.6908 125.993 24.4806C125.993 22.2704 124.163 20.4288 121.945 20.4288C119.728 20.4288 117.915 22.2704 117.915 24.4806C117.915 26.6908 119.728 28.498 121.945 28.498ZM121.945 18.1486C125.465 18.1486 128.281 20.9726 128.281 24.4806C128.281 27.989 125.465 30.7782 121.945 30.7782C118.425 30.7782 115.627 27.989 115.627 24.4806C115.627 20.9726 118.425 18.1486 121.945 18.1486Z"
      fill="#0BC178"
    />
    <path
      d="M131.68 18.4115C131.697 18.2711 131.803 18.1486 131.997 18.1486H132.278C132.419 18.1486 132.525 18.2186 132.578 18.3239L136.45 26.6032H136.502L140.374 18.3239C140.427 18.2186 140.515 18.1486 140.673 18.1486H140.955C141.149 18.1486 141.255 18.2711 141.272 18.4115L143.349 30.1992C143.402 30.4449 143.261 30.603 143.032 30.603H141.431C141.272 30.603 141.131 30.4626 141.096 30.3397L140.058 23.6915H140.005L136.925 30.5854C136.89 30.6902 136.784 30.7783 136.626 30.7783H136.309C136.15 30.7783 136.063 30.6902 136.01 30.5854L132.894 23.6915H132.841L131.821 30.3397C131.803 30.4626 131.662 30.603 131.504 30.603H129.92C129.691 30.603 129.55 30.4449 129.585 30.1992L131.68 18.4115Z"
      fill="#0BC178"
    />
    <path
      d="M150.054 24.0075C151.039 24.0075 151.796 23.2183 151.796 22.183C151.796 21.2182 151.039 20.4819 150.054 20.4819H147.942V24.0075H150.054ZM145.654 18.6575C145.654 18.4818 145.795 18.3241 145.988 18.3241H150.124C152.289 18.3241 154.067 20.0781 154.067 22.2002C154.067 24.376 152.289 26.1472 150.142 26.1472H147.942V30.2695C147.942 30.4452 147.783 30.6028 147.607 30.6028H145.988C145.795 30.6028 145.654 30.4452 145.654 30.2695V18.6575Z"
      fill="#0BC178"
    />
    <path
      d="M161.347 26.4105L159.588 22.5516H159.535L157.81 26.4105H161.347ZM153.744 30.147L159.218 18.3412C159.271 18.2364 159.359 18.1484 159.517 18.1484H159.693C159.869 18.1484 159.94 18.2364 159.992 18.3412L165.413 30.147C165.519 30.3747 165.378 30.6028 165.114 30.6028H163.583C163.319 30.6028 163.196 30.4975 163.072 30.2522L162.21 28.3578H156.947L156.085 30.2522C156.014 30.4275 155.856 30.6028 155.575 30.6028H154.044C153.779 30.6028 153.638 30.3747 153.744 30.147Z"
      fill="#0BC178"
    />
    <path
      d="M167.178 18.4645C167.178 18.2887 167.336 18.1483 167.512 18.1483H167.776C167.899 18.1483 167.987 18.1835 168.075 18.2711L175.256 25.9018H175.291V18.6573C175.291 18.4816 175.432 18.324 175.626 18.324H177.245C177.421 18.324 177.58 18.4816 177.58 18.6573V30.4626C177.58 30.6379 177.421 30.7784 177.245 30.7784H176.999C176.875 30.7784 176.787 30.7431 176.699 30.6555L169.483 22.7272H169.448V30.2698C169.448 30.445 169.307 30.6031 169.113 30.6031H167.512C167.336 30.6031 167.178 30.445 167.178 30.2698V18.4645Z"
      fill="#0BC178"
    />
    <path
      d="M183.065 24.6914L179.052 18.8324C178.911 18.6043 179.052 18.3242 179.334 18.3242H181.129C181.269 18.3242 181.358 18.4114 181.41 18.4818L184.226 22.5165L187.042 18.4818C187.096 18.4114 187.183 18.3242 187.324 18.3242H189.137C189.419 18.3242 189.559 18.6043 189.419 18.8324L185.353 24.6743V30.2695C185.353 30.4452 185.194 30.6029 185.019 30.6029H183.399C183.206 30.6029 183.065 30.4452 183.065 30.2695V24.6914Z"
      fill="#0BC178"
    />
  </svg>
);

export default MainLogo;
