import React, { useLayoutEffect } from 'react';
import {
  Slide,
  ToastContainer as Container,
} from 'react-toastify';
import { useTheme } from '@mui/material/styles';

import injectStyle from './injectStyle';

import './styles.scss';

const ToastContainer = (props) => {
  const theme = useTheme();

  useLayoutEffect(() => injectStyle(theme));

  return (
    <Container
      position='bottom-left'
      autoClose={3000}
      icon={false}
      closeOnClick
      theme="colored"
      bodyClassName="Toastify__toast-body-custom"
      transition={Slide}
      closeButton={false}
      {...props}
    />
  );
};

export default ToastContainer;
