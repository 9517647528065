import React from 'react';
import { Controller } from 'react-hook-form';

import TextField from 'components/ui/TextField';

const TextFieldForm = (props) => {
  const { name, helperText, disabledHelperText, control, placeholder, ...other } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TextField
          inputRef={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={placeholder}
          {...other}
        />
      )}
    />
  );
};

export default React.memo(TextFieldForm);
