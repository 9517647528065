import { useMediaQuery } from '@mui/material';

import { MAX_SIZE_MOBILE_RESOLUTION, MIN_SIZE_MOBILE_RESOLUTION } from '@constants';

const useIsMobile = () =>
  useMediaQuery(
    `only screen and (min-width: ${MIN_SIZE_MOBILE_RESOLUTION}px) and (max-width: ${MAX_SIZE_MOBILE_RESOLUTION}px)`,
  );

export default useIsMobile;
