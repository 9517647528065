import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

const NoMatch = () => (
  <Box>
    <Box width={1} height={{ mobile: '72px', laptop: '80px' }} backgroundColor="secondary.dark" />
    <Box display="flex" alignItems="center" justifyContent="center" height="40vh">
      <Typography medium fonSize="32px">
        404 Page Not Found
      </Typography>
    </Box>
  </Box>
);
export default NoMatch;
