import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Collapse from 'components/ui/Collapse';
import Image from 'components/ui/Image';

import { OurServicesTextImages } from '../constants';

const OurServicesImage = ({ condition, openedService }) => {
  return (
    <Box sx={{ '& .MuiCollapse-root, & .MuiCollapse-wrapper': { height: '100% !important' } }}>
      <Collapse in={condition} timeout={0}>
        <Box
          p={{ mobile: '20px 0 0 0', laptop: 2.5 }}
          height={1}
          minWidth={{ mobile: 220, tablet: 480 }}
        >
          <Image
            src={OurServicesTextImages[openedService]}
            alt="service"
            sx={{ maxWidth: 584, maxHeight: 610, borderRadius: 2 }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default React.memo(OurServicesImage);

OurServicesImage.propTypes = {
  condition: PropTypes.bool,
  openedService: PropTypes.string,
};
