import React from 'react';

import HeadingWrapper from './HeadingWrapper';
import HeadingText from './HeadingText';

const PageHeading = ({ title, text, buttonText, titleWidth }) => {
  return (
    <HeadingWrapper>
      <HeadingText title={title} text={text} buttonText={buttonText} titleWidth={titleWidth} />
    </HeadingWrapper>
  );
};

export default PageHeading;
