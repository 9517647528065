import React from 'react';

import Box from 'components/ui/Box';
import { MAX_WIDTH_CONTENT } from '@constants';

import FooterTop from './FooterTop';
import FooterBottom from './FooterBottom';

const Footer = () => (
  <Box component="footer" backgroundColor="secondary.dark" width={1}>
    <Box
      pb={{ mobile: 4, tablet: 2.5, laptop: 2.5 }}
      pt={{ mobile: 4, tablet: 7.5, laptop: 12.5 }}
      width={1}
      maxWidth={MAX_WIDTH_CONTENT}
      mx="auto"
    >
      <FooterTop />
      <FooterBottom />
    </Box>
  </Box>
);

export default Footer;
