import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { ModalContactFormWrapper } from 'components/common/ContactUs';

const BannerPartner = ({ title, text }) => {
  return (
    <Box
      px={{ mobile: 2.5, tablet: 7.5, laptop: 12.5 }}
      py={{ mobile: 12.5, tablet: 18.75 }}
      backgroundColor="primary.main"
      display="flex"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center" maxWidth={840}>
        <Typography
          variant="h2"
          color="secondary.main"
          extraBold
          fontSize={{ mobile: 40, tablet: 80 }}
          lineHeight={{ mobile: '48px', tablet: '88px' }}
          textAlign="center"
        >
          {title}
        </Typography>
        <Typography
          color="secondary.main"
          medium
          fontSize={{ mobile: 16, tablet: 24 }}
          lineHeight={{ mobile: '24px', tablet: '36px' }}
          mt={3}
          textAlign="center"
        >
          {text}
        </Typography>
        <ModalContactFormWrapper
          buttonProps={{ buttonTypes: 'outlined', size: 'fit-content', sx: { mt: 6.25 } }}
          buttonText="Let's grow together!"
        />
      </Box>
    </Box>
  );
};

export default React.memo(BannerPartner);

BannerPartner.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};
