import React from 'react';

import Box from 'components/ui/Box'

const Icon = React.forwardRef((props, ref) => {
  const {
    beforeProps,
    className='',
    color,
    cursor,
    disabled,
    fontSize = 24,
    name,
    hover,
    width = 24,
    ...other
  } = props;

  return (
    <Box
      className={`icon icon-${name} ${className}`}
      component="i"
      ref={ref}
      color={color}
      fontSize={fontSize}
      width={width}
      minWidth={width}
      height={width}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      {...other}
    />
  );
});

export default React.memo(Icon);
