import React from 'react';

import PeopleSectionWrapper from './PeopleSectionWrapper';
import PeopleHeading from './PeopleHeading';
import PeopleInfo from './PeopleInfo';

const PeopleSection = () => (
  <PeopleSectionWrapper>
    <PeopleHeading />
    <PeopleInfo />
  </PeopleSectionWrapper>
);

export default PeopleSection;
