import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Collapse from 'components/ui/Collapse';
import Button from 'components/ui/Button';
import Icon from 'components/ui/Icon';
import { HeaderSection } from 'components/common/ContentHeaderSection';
import { MAX_WIDTH_CONTENT, OUR_VALUES } from '@constants';
import { ROUTES } from 'routes';

const TextTitle = React.memo(({ title, changeText, textType, iconName, openedValue }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ ...(openedValue !== textType && { cursor: 'pointer' }) }}
      justifyContent="space-between"
      px={0}
      py={1.5}
      onClick={changeText(textType)}
    >
      <Typography variant="h3" fontSize={20} lineHeight="28px" extraBold>
        {title}
      </Typography>
      <Icon
        name={iconName}
        width="fit-content"
        fontSize={39}
        height={48}
        ml={2}
        sx={{
          transition: (theme) => theme.transitions.create('opacity', { duration: 300 }),
          opacity: 1,
        }}
      />
    </Box>
  );
});

const CollapsedText = React.memo(({ text, condition }) => {
  return (
    <Box display="flex" height={1}>
      <Collapse in={condition} sx={{ '& .MuiCollapse-wrapper': { height: 1 } }}>
        <Box display="flex" height={1} flexDirection="column" justifyContent="space-between">
          <Typography color="white.light" pb={1.5} pt={0.5} px={0}>
            {text}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
});

const OurValueBody = () => {
  const [openedValue, setOpenedValue] = useState(OUR_VALUES[0].id);

  const changeText = useCallback((variant) => () => setOpenedValue(variant), []);

  return useMemo(
    () => (
      <Box
        display={{ laptop: 'flex' }}
        height={{ laptop: 750 }}
        mx="auto"
        width={1}
        maxWidth={MAX_WIDTH_CONTENT}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
          py={{ mobile: 6, tablet: 10, laptop: 2 }}
          width={{ laptop: '50%' }}
        >
          <Box display="flex" flexDirection="column" gap={{ mobile: 6.25, laptop: 4 }}>
            <HeaderSection
              title="A compass that guides every decision"
              subTitle="Our values"
              titleProps={{ sx: { color: 'secondary.main' } }}
            />
            <Typography color="white.light">
              The exceptional professional journey isn't just about guiding the ship, it's about
              setting sail with purpose and passion. Our value book isn't just a document &
              meaningless words, it's a compass that guides every decision, every interaction and
              every innovation that propels us forward. It was created by people who have defined
              below what they feel, respect and represent.
            </Typography>
            <Button to={ROUTES.vision} size="fit-content">
              Navigate to us
            </Button>
          </Box>
        </Box>

        <Box
          backgroundColor="secondary.contrastText"
          color="secondary.main"
          display="flex"
          flexDirection="column"
          px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
          py={{ mobile: 2, tablet: 10, laptop: '7%' }}
          width={{ laptop: '50%' }}
          sx={{ overflowY: 'hidden' }}
        >
          {OUR_VALUES.map((item) => (
            <Box
              key={item.id}
              display="flex"
              flexDirection="column"
              flex={openedValue === item.id ? 1 : 0}
              borderBottom={1}
              borderColor={item.id !== 'environment' ? 'white.border' : 'transparent'}
            >
              <TextTitle
                title={item.title}
                changeText={changeText}
                textType={item.id}
                iconName={item.iconName}
                openedValue={openedValue}
              />
              <CollapsedText condition={openedValue === item.id} text={item.text} />
            </Box>
          ))}
        </Box>
      </Box>
    ),
    [openedValue, changeText],
  );
};

export default OurValueBody;

TextTitle.propTypes = {
  title: PropTypes.string,
  changeText: PropTypes.func,
  textType: PropTypes.string,
  iconName: PropTypes.string,
  openedValue: PropTypes.string,
};

CollapsedText.propTypes = {
  text: PropTypes.object,
  condition: PropTypes.bool,
};
