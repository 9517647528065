import React from 'react';

import Box from 'components/ui/Box';
import PeopleSection from './components/PeopleSection';
import { BannerPartner } from 'components/common/BannerPartner';
import { JoinUsSection } from 'components/common/JoinUsSection';
import { PageHeading } from 'components/common/PageHeading';
import { useScrollToTop } from 'components/layout/hooks';

const People = () => {
  useScrollToTop();

  return (
    <Box>
      <PageHeading
        title="Driving force behind our success"
        text="Discover the individuals behind our success."
      />
      <PeopleSection />
      <BannerPartner
        title="We aspire to become your trusted people advisors"
        text="helping you to elevate your leadership potential and assisting your organizations in discovering unparalleled talent."
      />
      <JoinUsSection />
    </Box>
  );
};

export default People;
