import React from 'react';

import SectionWrapper from 'pages/Landing/components/SectionWrapper';

import OurValueBody from './OurValueBody';

const OurValues = () => {
  return (
    <SectionWrapper backgroundColor="secondary.dark" p={0} wrapper="true">
      <OurValueBody />
    </SectionWrapper>
  );
};

export default OurValues;
