import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { HeaderSection } from 'components/common/ContentHeaderSection';

const PeopleHeading = () => (
  <Box maxWidth="800px" mx="auto" textAlign="center" pb={5}>
    <HeaderSection
      title="Your trusted people advisors"
      subTitle=" Our people"
      subTitleProps={{ textAlign: 'center' }}
      titleProps={{ textAlign: 'center' }}
    />
    <Typography medium color="text.simple" pt={3.5} pb={1.5}>
      Our diverse team brings a wealth of experience, enabling us to provide customized solutions
      that drive business success. By combining our expertise in people development, psychology,
      coaching, and talent assessment, we unlock potential and cultivate excellence in leadership at
      every level.
    </Typography>
    <Typography medium color="text.simple" pt={1.5} pb={2.5}>
      Over the years we have managed to bring together a passionate team of experienced people -
      development professionals committed to helping you navigate the complexities of your
      professional journey.
    </Typography>
  </Box>
);

export default PeopleHeading;
