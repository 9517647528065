import React from 'react';
import { NavLink } from 'react-router-dom';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { MAX_WIDTH_CONTENT } from '@constants';
import { ROUTES } from 'routes/config';

const date = new Date();

const FooterBottom = () => (
  <Box
    display="flex"
    maxWidth={MAX_WIDTH_CONTENT}
    mx="auto"
    my={0}
    flexDirection={{ tablet: 'row-reverse' }}
    justifyContent={{ laptop: 'space-between' }}
    px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
  >
    <Box component="nav" width={{ mobile: '50%', laptop: 'auto' }}>
      <Box component="ul" color="white.light" display={{ tablet: 'flex' }} fontSize="10px">
        <Box component="li" pb={1.25} pr={{ tablet: 2.25 }}>
          <NavLink to={ROUTES.privacy}>Privacy Policy</NavLink>
        </Box>
        <Box component="li" pb={1.25} pr={{ tablet: 2.25 }}>
          Terms of Service
        </Box>
        <Box component="li" pb={1.25} pr={{ tablet: 2.25 }}>
          Cookies Settings
        </Box>
      </Box>
    </Box>
    <Typography color="white.light" fontSize="10px" width="50%" alignSelf="flex-end">
      © {date.getFullYear()} HCC. All rights reserved.
    </Typography>
  </Box>
);

export default FooterBottom;
