import React from 'react';

import SectionWrapper from './SectionWrapper';
import OurPeopleBody from './OurPeopleBody';

const OurServices = () => {
  return (
    <SectionWrapper>
      <OurPeopleBody />
    </SectionWrapper>
  );
};

export default OurServices;
