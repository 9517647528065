export const PEOPLE = [
  {
    id: '1',
    name: 'Mirek Kołcz',
    job: 'Managing Partner & Co-founder',
    email: 'miroslaw.kolcz@hcconsulting.pl',
    phone: '+48 608 762 732',
    socialsLink: 'https://www.linkedin.com/in/mirek-kolcz/',
    text: [
      "Whether it's guiding you through pivotal career decisions, igniting leadership potential, or finding the perfect executive match, we're here to make a meaningful difference in your life and your business.",
      "Together, let's unlock your true potential and embark on a journey of growth, fulfillment, and success",
    ],
    image: require('assets/images/people/Mirek-Kolcz.png'),
  },
  {
    id: '2',
    name: 'Agata Klonowska',
    job: 'Managing Partner & Co-founder',
    email: 'agata.klonowska@hcconsulting.pl',
    phone: '+48 608 762 762',
    socialsLink: 'https://www.linkedin.com/in/agata-klonowska-a203703b/',
    text: [
      'Operational excellence is the key focus of any business hoping to succeed in services industry and it is the same case for HCC.',
      'What is not common is that we truly put People First and it is my job to keep it that way. We believe that transparent approach along with constructive feedback are the cornerstones to unlocking our potential to grow both individually and as an organization.',
    ],
    image: require('assets/images/people/Agata-Klonowska.png'),
  },
  {
    id: '3',
    name: 'Aleksandra Grałek',
    job: 'HR Business Partner',
    email: 'aleksandra.gralek@hcconsulting.pl',
    phone: '+48 533 400 955',
    socialsLink: 'https://www.linkedin.com/in/aleksandra-gra%C5%82ek-33648b92/',
    text: [
      'With my passion for diversity and love of excellent quality in every aspect of my life I have found HCC to be the best place to work.',
      'A place where identifying one’s individual potential, developing talents, building unique atmosphere of trust and cooperation is just business as usual.',
    ],
    image: require('assets/images/people/Aleksandra-Gralek.png'),
  },
  {
    id: '4',
    name: 'Ewelina Jastrzębska-Latek',
    job: 'Senior Manager - Leadership Development',
    email: 'ewelina.jastrzebska-latek@hcconsulting.pl',
    phone: '+48 608 762 712',
    socialsLink: 'https://www.linkedin.com/in/ewelina-jastrz%C4%99bska-latek-46108ab9/',
    text: [
      'Responsibility is ingrained in our culture - each individual takes ownership of their actions and their consequences.',
      'We are all accountable for positive work environment, where initiatives, collaboration, and strong interpersonal relationships are encouraged, makes each day at work fulfilling and motivating.',
    ],
    image: require('assets/images/people/Ewelina-Jastrzebska.png'),
  },
  {
    id: '5',
    name: 'Katarzyna Kołodziejak',
    job: 'Program Manager',
    email: 'katarzyna.kolodziejak@hcconsulting.pl',
    phone: '+48 533 400 083',
    socialsLink: 'https://www.linkedin.com/in/katarzyna-ko%C5%82odziejak-58776945/',
    text: [
      'Our dedication to quality and professionalism ensures that every client receives exceptional service. Responsibility is not just a word but a way of life at HCC - each team member takes ownership of their actions and their outcomes.',
      'The positive work environment, where innovation, teamwork and strong interpersonal connections thrive, makes every day at HCC fulfilling and inspiring.',
    ],
    image: require('assets/images/people/Katarzyna-Kolodziejak.png'),
  },
];
