import React from 'react';

import Box from 'components/ui/Box';
import { JoinUsSection } from 'components/common/JoinUsSection';
import { PageHeading } from 'components/common/PageHeading';

import { PageHeaderSection, ServiceBlock } from './components';
import { MAX_WIDTH_SERVICE_CONTENT } from './constants';
import { useScrollToTop } from 'components/layout/hooks';
import { Link } from 'react-router-dom';

const Text = () => (
  <>
    We redefine leadership through a unique blend of{' '}
    <Link to="#career" state={{ anchor: 'career' }}>
      Career Advisory
    </Link>
    ,{' '}
    <Link to="#leadership" state={{ anchor: 'leadership' }}>
      Leadership Development
    </Link>{' '}
    and{' '}
    <Link to="#search" state={{ anchor: 'search' }}>
      Executive Search
    </Link>
    .
  </>
);

const Services = () => {
  useScrollToTop();

  return (
    <Box>
      <PageHeading
        title="Experience our transformative and people-centric approach"
        text={<Text />}
        buttonText="Let's talk about your growth!"
      />
      <Box
        display="flex"
        flexDirection="column"
        px={{ mobile: 2.5, tablet: 7.5, laptop: '7%' }}
        py={{ mobile: 2.5, tablet: 7 }}
      >
        <PageHeaderSection />
        <ServiceBlock />
        <JoinUsSection
          innerWrapperProps={{ sx: { p: 0, mt: 8 } }}
          wrapperProps={{ maxWidth: MAX_WIDTH_SERVICE_CONTENT }}
        />
      </Box>
    </Box>
  );
};

export default Services;
