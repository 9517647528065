import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

import { ModalContactFormWrapper } from 'components/common/ContactUs';

const ServiceBlockText = ({ textSubtitle, text, title, buttonProps, titleProps, textProps }) => {
  return (
    <Box display="flex" flexDirection="column" maxWidth={{ mobile: 1, laptop: 592 }}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" height={1} p={3.75}>
        <Box display="flex" flexDirection="column">
          <Typography fontSize={24} lineHeight="32px" extraBold {...titleProps}>
            {title}
            <br />
            <br />
          </Typography>
          <Typography medium {...textProps}>
            {textSubtitle}
            <br />
            <br />
            {text}
          </Typography>
        </Box>
        <ModalContactFormWrapper
          buttonProps={{ buttonTypes: 'link', size: 'fit-content' }}
          buttonText="Let’s grow together"
        />
      </Box>
    </Box>
  );
};

export default React.memo(ServiceBlockText);

ServiceBlockText.propTypes = {
  textSubtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  buttonProps: PropTypes.object,
  titleProps: PropTypes.object,
  textProps: PropTypes.object,
};
