import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

import { MAX_WIDTH_CONTENT } from '@constants';
import List from 'components/ui/List';
import ListItem from 'components/ui/ListItem/ListItem';

import { useScrollToTop } from 'components/layout/hooks';

const SubTitle = ({ title }) => (
  <Typography
    variant="h2"
    extraBold
    fontSize={{ mobile: 24, tablet: 32 }}
    lineHeight={{ mobile: '32px', tablet: '40px' }}
    py={1.5}
  >
    {title}
  </Typography>
);

const ParagraphText = ({ text }) => <Typography pb={2.5}>{text}</Typography>;

const Privacy = () => {
  useScrollToTop();

  return (
    <Box>
      <Box width={1} height={{ mobile: '72px', laptop: '80px' }} backgroundColor="secondary.dark" />

      <Box
        maxWidth={MAX_WIDTH_CONTENT}
        mx="auto"
        sx={{
          '& a': { color: 'primary.main', fontSize: 'inherit' },
          '& a:hover': { color: 'primary.hover' },
        }}
      >
        <Box px={{ mobile: 2, tablet: 7.5, laptop: '7%' }} py={{ mobile: 2, tablet: 7.5 }}>
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            maxWidth="800px"
            mx="auto"
            textAlign="center"
            pb={{ mobile: 2, tablet: 7.5 }}
          >
            <Typography
              variant="h1"
              extraBold
              fontSize={{ mobile: 32, tablet: 56 }}
              lineHeight={{ mobile: '40px', tablet: '64px' }}
            >
              Privacy Policy
            </Typography>
            <Typography medium fontSize="20px">
              STATEMENT OF HCC EXPERT Sp. z o. o. DATA PRIVACY POLICY
            </Typography>
          </Box>
          <SubTitle title="PRIVACY STATEMENT" />
          <ParagraphText
            text="This statement provides you with notice as to why HCC Expert Sp. z o. o. and/or its
          subsidiaries (individually and collectively referred to herein as “HCC”) collect your
          personal data, how it is intended to be used, to whom your personal data may be
          transferred to, how to access, review and amend your personal data, and our policies on
          direct marketing and the use of cookies. By using this website, you are accepting the
          practices and policies in this privacy statement. If you object to any practices and
          policies in this statement, please do not use this website to submit your personal
          information to HCC Exeprt Sp. z o. o."
          />
          <ParagraphText
            text="This website is for general information purposes only. While we use reasonable efforts to
          ensure the accuracy of the information on this website, HCC Expert Sp. z o. o. does not
          warrant its absolute accuracy or accept any liability for any loss or damage resulting
          from any inaccuracy or omission. Without prior permission from HCC Expert Sp. z o. o., no
          information contained on this website may be copied, except for personal use, or
          redistributed."
          />
          <ParagraphText
            text="HCC Expert Sp. z o. o. recognizes its responsibilities in relation to the collection,
          holding, processing, or use of personal data. The provision of your personal data is
          voluntary. You may choose not to provide us with the requested data, but failure to do so
          may inhibit our ability to do business with or provide services to you. HCC Expert Sp. z
          o. o. will not collect any information that identifies you personally through this website
          unless and until you buy our products or services, register as a member, or submit
          personal information for job application purposes."
          />
          <ParagraphText
            text="This website and our social media platforms are not intended for persons in jurisdictions
          that restrict the distribution of information by us or use of such social media platforms.
          If this is applicable to you, we would advise you to study the relevant restrictions, and
          HCC Expert Sp. z o. o. does not accept liability in this respect."
          />
          <SubTitle title="HOW WE COLLECT PERSONAL DATA" />
          <ParagraphText
            text="We will collect and store any information when you enter our website, or provide it to us
          through any other channel. We may also obtain lawfully collected personal or non-personal
          information about you from affiliated entities, business partners, and other independent
          third-party sources. We may also collect some information about your computer or other
          devices used when you visit this website. If you make use of any social media features or
          platforms, either on our website, an application we provide or otherwise through a social
          media provider, we may access and collect information about you via that social media
          provider in accordance with their policies. When using a social media feature, we may
          access and collect the information you have chosen to make available and to include in
          your social media profile or account, including but not limited to your name, gender,
          birthday, email address, address, location, etc. Our access to this information may be
          limited or blocked based on your privacy settings with the relevant social media provider."
          />
          <SubTitle title="WHY WE COLLECT YOUR PERSONAL DATA AND HOW IT MAY BE USED?" />
          <ParagraphText text="Personal data is collected for the following purposes:" />
          <List pt={0} pb={2}>
            <ListItem>
              to process, administer, implement and effect the requests or transactions contemplated
              by the forms available on our website or any other documents you may submit to us from
              time to time;
            </ListItem>
            <ListItem>
              to design new or enhance existing products and services provided by us;
            </ListItem>
            <ListItem>
              to communicate with you including to send you administrative communications about any
              account you may have with us or about future changes to this privacy statement;
            </ListItem>
            <ListItem>
              for statistical or research undertaken by HCC Expert Sp. z o. o. or our respective
              partners;
            </ListItem>
            <ListItem>for data matching, internal business and administrative purposes;</ListItem>
            <ListItem>
              for promotional or marketing purposes including sending you promotional materials and
              conducting marketing activities in relation to products, services, and subjects:
              leadership; people & organization development; educational; recruitment; training
              workshop; reward/loyalty/privilege programs;
            </ListItem>
            <ListItem>
              to personalize the appearance of our websites, provide recommendations of relevant
              products, and provide targeted advertising on our website or through other channels;
            </ListItem>
            <ListItem> other purposes as notified at the time of collection;</ListItem>
            <ListItem> other purposes directly relating to any of the above.</ListItem>
          </List>
          <ParagraphText
            text="By providing your personal information to us, you accept that HCC Expert Sp. z o. o. may
          retain your information for as long as necessary, to fulfill the purpose(s) for which it
          is collected in compliance with applicable laws and regulations. HCC Expert Sp. z o. o.
          applies security measures consistent with industry-standard practices to protect your
          personal information. These measures include technical, procedural, monitoring, and
          tracking steps intended to safeguard data from misuse, unauthorized access or disclosure,
          loss, alteration, or destruction."
          />
          <SubTitle title="WHO MAY BE PROVIDED WITH YOUR PERSONAL DATA?" />
          <ParagraphText
            text="Personal data will be kept confidential but may, where permitted by law and where such
          disclosure is necessary to satisfy the purpose or a directly related purpose for which the
          personal data was collected, provide such personal data to the following parties:"
          />
          <List>
            <ListItem>
              any person authorized to act as a HCC Expert Sp. z o. o. Person for the sale,
              distribution, or servicing of products and services offered by HCC Expert Sp. z o. o.;
            </ListItem>
            <ListItem>
              any person who is employed or contracted to work for such HCC Expert Sp. z o. o.
              Person for the sale, distribution or servicing of products and services offered by HCC
              Expert Sp. z o. o. to you;
            </ListItem>
            <ListItem>
              any agent, contractor, or third-party service who provides administration, data
              processing, telecommunications, computer, payment, technology outsourcing, mailing,
              and printing services in connection with the operation of HCC Expert Sp. z o. o.
              business and HCC Expert’s Sp. z o. o. provision of services to you;
            </ListItem>
            <ListItem>
              other companies that help gather your information or communicate with you, such as
              research companies in order to enhance the services, we provide to you; and HCC
              Expert’s business partner or third-party online survey provider who collects and
              stores user information in a secure and private manner on behalf of HCC Expert Sp. z
              o. o. to manage online surveys/assessments. This provider allows HCC Expert Sp. z o.
              o. to create surveys and questionnaires for distribution to survey respondents.
            </ListItem>
            <ListItem>
              government or regulatory bodies or any person to whom HCC Exper Sp. z o. o. must
              disclose data under a legal and/or regulatory obligation in that jurisdiction
              applicable to that particular HCC Expert Sp. z o. o.
            </ListItem>
          </List>
          <ParagraphText
            text="Where permitted by law, your personal data may be provided to any of the above parties who
          may be located in Poland or outside of Poland. Your information may be transferred to,
          stored, and processed in Poland or any other business partners where a third-party
          contractor is located or from which the third-party contractor provides us services. By
          providing us with your personal information or using our services or our website, you
          consent to the transfer of such information outside your jurisdiction to our facilities or
          to those third parties with whom we share it as described above."
          />
          <SubTitle title="ACCESS RIGHTS TO PERSONAL DATA" />
          <ParagraphText text="You have the right to:" />
          <List>
            <ListItem>
              verify whether HCC Expert Sp. z o. o. holds any personal data about you and to access
              any such data;
            </ListItem>
            <ListItem>
              require HCC Expert Sp. z o. o. to correct or update any personal data relating to you
              which is inaccurate, incomplete or not up to date;
            </ListItem>
            <ListItem>
              require HCC Expert Sp. z o. o. to erase, destroy, temporary suspend, or convert any
              personal data into anonymized data;
            </ListItem>
            <ListItem>
              withdraw your consent provided to HCC Expert Sp. z o. o., and enquire about HCC Expert
              Sp. z o. o. policies and practices in relation to personal data.
            </ListItem>
          </List>
          <ParagraphText
            text="You may use the rights described above and/or express any concern you might have about our
        use of your personal data by contacting:"
          />
          <Typography pb={1.5}>
            HCC Expert Sp. z o. o. <br />
            ul. Wałbrzyska 11 lok. 252, <br />
            02-739 Warszawa <br />
            e-mail:{' '}
            <a href="mailto:office@hcconsulting.pl" rel="noreferrer nofollow">
              office@hcconsulting.pl
            </a>
            <br />
            NIP: 951- 24-26-416, <br />
            REGON: 366260849
          </Typography>
          <ParagraphText
            text="If you ask us to erase, destroy, temporarily suspend, convert any personal data into anonymized
        data, or withdraw your consent, it may inhibit our ability to do business with or provide
        services to you."
          />
          <SubTitle title="USE OF COOKIES" />
          <ParagraphText
            text="Cookies are unique identifiers placed on your computer or other devices by a web server,
          which contains information that can later be read by the server that issued the cookie to
          you. HCC Exper Sp. z o. o. may use cookies on various websites we maintain. The
          information collected (including but not limited to: your IP addresses (and domain names),
          browser software, types and configurations of your browser, language settings,
          geo-locations, operating systems, referring website, pages and content viewed, and
          duration of visit) will be used for compiling aggregate statistics on how our visitors
          reach and browse our websites to help us understand how we can improve your experience on
          it. Such information is collected anonymously and you cannot be identified unless you have
          logged on as a member. We use such data only for website enhancement and optimization
          purposes. The cookies also enable our website to remember you and your preferences and
          tailor the website for your needs. Advertising cookies will allow us to provide
          advertisements on our websites that are as relevant to you as possible, e.g. by selecting
          interest-based advertisements for you or preventing the same advertisements from
          constantly reappearing to you."
          />
          <ParagraphText
            text="Most web browsers are initially set up to accept cookies. If you do not want to receive
          cookies, you can disable this function in your browser settings. However, by doing so you
          may not be able to fully enjoy the benefits of our websites and certain features may not
          work properly."
          />
          <SubTitle title="EXTERNAL LINKS" />
          <ParagraphText
            text="If any part of this website contains links to other websites, those sites may not operate
        under this privacy statement. You are advised to check the privacy statements on those
        websites to understand their policies on the collection, usage, transferal, and disclosure
        of personal data."
          />
          <SubTitle title="AMENDMENTS TO THIS PRIVACY STATEMENT" />
          <ParagraphText
            text="HCC Expert Sp. z o. o. reserves the right, at any time and without notice, to add to,
          change, update or modify this privacy statement, simply by notifying you of such change,
          update, or modification. If we decide to change our personal data policy, those changes
          will be notified on our website so that you are always aware of what information we
          collect, how we use the information, and under what circumstances the information is
          disclosed. Any such change, update, or modification will be effective immediately upon
          posting."
          />
          <SubTitle title="ADDITIONAL INFORMATION" />
          <ParagraphText
            text="Should you have any questions on any part of this privacy statement or would like
          additional information regarding HCC Expert’s Sp. z o .o. s data privacy practices please
          do not hesitate to contact us by the contact details above."
          />
        </Box>
      </Box>
    </Box>
  );
};
export default Privacy;
