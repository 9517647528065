import React from 'react';

import {
  createTheme,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

import shadows from './shadows';
import { Fonts } from './Fonts';

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 320,
      tablet: 744,
      laptop: 1024,
      desktop: 9999,
    },
  },
  typography: {
    allVariants: {
      fontFamily: Fonts.Regular,
      color: '#051937',
      letterSpacing: 0,
    },
  },
  spacing: 8,
  palette: {
    background: {
      default: '#FFFFFF',
    },
    primary: {
      main: '#0BC178',
      light: '#0CD182',
      dark: '#00513C',
      hover: '#006c51',
      contrastText: '#B6B8BC',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#051937',
      light: '#F6F6FC',
      contrastText: '#092550',
    },
    text: {
      primary: '#051937',
      secondary: '#181C22',
      disabled: '#8D9199',
      simple: '#505e73',
    },
    divider: '#B9BEC7',
    action: {
      hover: '#FFFFFF',
      active: '#EEF0FA',
    },
    gray: {
      main: '#74777F',
      light: '#D0D5DD',
      dark: '#F5F5F7',
      contrastText: '#B9BEC7',
      background: '#E2E2E5',
      text: '#ADB3BD',
    },
    purple: {
      light: '#D6BBFB',
    },
    red: {
      main: '#DE3730',
      light: '#FDA29B',
    },
    white: {
      light: 'rgba(255, 255, 255, 0.7)',
      border: 'rgba(255, 255, 255, 0.2)',
    },
  },
  shadows,
});

const ThemeProvider = (props) => (
  <StyledEngineProvider>
    <MuiThemeProvider theme={theme}>
      <GlobalStyles
        styles={(theme) => ({
          body: { color: theme.palette.text.primary, fontFamily: Fonts.Regular },
        })}
      />
      {props.children}
    </MuiThemeProvider>
  </StyledEngineProvider>
);

export default ThemeProvider;
