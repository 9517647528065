import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Collapse from 'components/ui/Collapse';
import { Fonts } from 'assets/theme';

const FormPhoneInput = ({ control, name, error }) => {
  const [country, setCountry] = useState({ name: 'pl', code: 48, number: '' });
  return (
    <Box
      width={1}
      minWidth={150}
      sx={{
        '& .selected-flag': {
          paddingLeft: 1.75,
          width: 1,
          '&:hover': { backgroundColor: 'transparent' },
        },
        '& .selected-flag .flag': {
          backgroundImage: 'none',
          position: 'static',
          display: 'flex',
          alignItems: 'center',
          width: 1,
          height: 1,
          marginTop: 0,
        },
        '& .selected-flag .flag .arrow': {
          left: 10,
          top: 0,
          mt: 0,
          borderLeft: 5,
          borderRight: 5,
          borderTop: 5,
          borderColor: 'transparent',
          borderTopColor: 'gray.main',
        },
        '& .selected-flag .flag:before': {
          content: `"${country.name.toUpperCase()}"`,
          fontSize: 16,
          fontWeight: 500,
        },
        '& .flag-dropdown ': {
          backgroundColor: 'secondary.main',
          width: 70,
          display: 'flex',
          alignItems: 'center',
          borderRight: 'none',
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          borderColor: 'gray.light',
          ...(error && {
            borderColor: 'red.light',
            '&:focus': {
              boxShadow: 3,
              borderColor: 'red.light',
            },
          }),
        },
        '& .form-control': {
          borderRadius: 2,
          color: country.number === country.code ? 'gray.main' : 'text.primary',
          fontWeight: 500,
          borderColor: 'gray.light',
          boxShadow: 1,
          '&:focus': {
            boxShadow: 2,
            borderColor: 'purple.light',
            color: 'text.primary',
          },
          ...(error && {
            borderColor: 'red.light',
            '&:focus': {
              boxShadow: 3,
              borderColor: 'red.light',
            },
          }),
          '&:disabled': {
            borderColor: 'gray.contrastText',
            color: 'secondary.light',
            boxShadow: 1,
          },
        },
      }}
    >
      <Box
        component="label"
        display="flex"
        flexDirection="column"
        gap="6px"
        fontFamily={Fonts.SemiBold}
        fontSize={14}
        lineHeight="20px"
      >
        Phone number
        <Controller
          control={control}
          name={name}
          defaultValue=""
          render={({ field: { onChange, ref, value } }) => (
            <PhoneInput
              ref={ref}
              name="phoneNumber"
              value={value}
              placeholder={`+${country.code}`}
              onChange={(value, data) => {
                if (data?.countryCode)
                  setCountry({ name: data.countryCode, code: data.dialCode, number: value });
                onChange(value);
              }}
              inputProps={{
                style: {
                  height: 48,
                  width: '100%',
                  padding: '10px 14px 10px 75px',
                  fontSize: 16,
                  lineHeight: '24px',
                },
              }}
            />
          )}
        />
        <Collapse in={Boolean(error?.message)}>
          <Typography color="red.main" fontSize={14} lineHeight="20px">
            {error?.message}
          </Typography>
        </Collapse>
      </Box>
    </Box>
  );
};

export default React.memo(FormPhoneInput);

FormPhoneInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.object,
};
