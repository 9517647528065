const shadows = [
  'none', // 0
  '0px 1px 2px 0px rgba(16, 24, 40, 0.05)', // 1
  '0px 0px 0px 4px rgba(158, 119, 237, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)', // 2
  '0px 0px 0px 4px rgba(240, 68, 56, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)', // 3
  '', // 4
  '', // 5
  '', // 6
  '', // 7
  '', // 8
  '', // 9
  '', // 10
  '', // 11
  '', // 12
  '', // 13
  '', // 14
  '', // 15
  '', // 16
  '', // 17
  '', // 18
  '', // 19
  '', // 20
  '', // 21
  '', // 22
  '', // 23
  '', // 24
];

export default shadows;
