import WebFont from 'webfontloader';

import { Fonts } from '../theme';

WebFont.load({
  custom: {
    families: [
      Fonts.Regular,
      Fonts.Medium,
      Fonts.SemiBold,
      Fonts.ExtraBold,
    ],
  },
});
