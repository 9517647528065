import React from 'react';
import Typography from 'components/ui/Typography';

export const OUR_VALUES = [
  {
    title: 'Trust & Respect',
    id: 'trust',
    text: (
      <>
        <Typography variant="span">
          Trust is the foundation of our interactions, built on reliability, confidence, and the
          belief that others will act with integrity.{' '}
        </Typography>
        <Typography variant="span">
          Respect makes for the cornerstone of our relationships, recognizing the worth and dignity
          of each individual, fostering an environment of mutual understanding and appreciation.
        </Typography>
      </>
    ),
    iconName: 'trust-respect',
  },
  {
    id: 'quality',
    title: 'Quality & Professionalism',
    text: (
      <>
        <Typography variant="span">
          At our core is professionalism, we are dedicated to providing superior service that
          reflects our unwavering commitment to excellence.{' '}
        </Typography>
        <Typography variant="span">
          We strive to provide best-in-class services customized to meet the specific needs of our
          valued business partners.
        </Typography>
      </>
    ),
    iconName: 'quality-proffessionalism',
  },
  {
    id: 'cooperation',
    title: 'Cooperation & Partnership',
    text: (
      <>
        <Typography variant="span">
          Through collaboration, communication, and a spirit of partnership, we leverage the diverse
          talents and perspectives of our team and clients to achieve mutual success.{' '}
        </Typography>
        <Typography variant="span">
          Together, we utilize our strengths and create opportunities for growth and innovation.
        </Typography>
      </>
    ),
    iconName: 'cooperation-partnership',
  },
  {
    id: 'ownership',
    title: 'Ownership & Responsibility',
    text: (
      <>
        <Typography variant="span">
          Taking ownership means recognizing and accepting the consequences of our actions and
          decisions.{' '}
        </Typography>
        <Typography variant="span">
          Responsibility entails understanding our role and obligations within our team and
          organization, and consistently delivering on them.
        </Typography>
      </>
    ),
    iconName: 'e-commerce',
  },
  {
    id: 'development',
    title: 'Development & Continuous Growth',
    text: (
      <>
        <Typography variant="span">We believe in the power of growth and development.</Typography>
        <Typography variant="span">
          Our commitment to continuous learning, innovation, and personal development helps us stay
          ahead of the curve, adapt to the evolving needs of the market, and seize opportunities for
          advancement.
        </Typography>
      </>
    ),
    iconName: 'development-growth',
  },

  {
    id: 'environment',
    title: 'Positive Work Environment',
    text: (
      <>
        <Typography variant="span">
          We take great care of an atmosphere that encourages productivity, collaboration, and
          personal growth among employees.{' '}
        </Typography>
        <Typography variant="span">
          At our company, we cultivate a culture of positivity and appreciation where every
          individual feels valued, empowered, and inspired to unleash their full potential.
        </Typography>
      </>
    ),
    iconName: 'positive-work-environment',
  },
];
