import React from 'react';

import { Checkbox as MuiCheckbox } from '@mui/material';

const Checkbox = React.forwardRef((props, ref) => {
  const { sx, ...others } = props;

  return (
    <MuiCheckbox
      ref={ref}
      disableRipple
      sx={{
        p: 0,
        '&:hover': {
          backgroundColor: 'secondary.light',
        },
        '&:active': {
          backgroundColor: 'action.active',
        },
        '&.Mui-checked:hover': {
          color: 'primary.light',
        },
        '&.Mui-checked:active': {
          color: 'primary.dark',
        },
        ...sx,
      }}
      {...others}
    />
  );
});

export default Checkbox;
