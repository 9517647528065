import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export const sendContactUsForm = (data) => {
  emailjs
    .send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      data,
      {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      },
    )
    .then(() => {
      toast.info('Message sent');
    })
    .catch((error) => {
      toast.info('Message was not sent');
      console.log('error', error);
    });
};
