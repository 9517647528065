import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { ModalContactFormWrapper } from 'components/common/ContactUs';

const HeadingText = () => {
  return (
    <Box
      color="secondary.main"
      width="100%"
      textAlign="left"
      pt={9}
      px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
    >
      <Box display="inline-flex" flex-direction="column" justify-content="space-between">
        <Box width={{ laptop: '588px' }} pb={{ mobile: 3, tablet: 6.5, laptop: '4vh' }}>
          <Typography
            variant="h1"
            extraBold
            fontSize={{ mobile: '32px', tablet: '80px' }}
            lineHeight={1.14}
          >
            Work with your trusted people advisor
          </Typography>
          <Typography
            pb={{ mobile: 3, tablet: 6, laptop: '4vh' }}
            pt={{ mobile: 1.5, tablet: 3, laptop: '2vh' }}
            fontFamily="ManropeMedium"
            fontSize={{ mobile: '16px', tablet: '20px' }}
            lineHeight={1.6}
          >
            At HCC Executive we redefine leadership through a unique blend of Career Advisory,
            Leadership Development and Executive Search Services.
          </Typography>
          <ModalContactFormWrapper buttonText="Let's grow together!" />
        </Box>
      </Box>
    </Box>
  );
};

export default HeadingText;
