import { ROUTES } from 'routes/config';

export const SERVICES_LINKS = [
  {
    displayedName: 'Career advisory',
    link: ROUTES.services,
    anchorLink: true,
    state: { anchor: 'career' },
  },
  {
    displayedName: 'Leadership development',
    link: ROUTES.services,
    anchorLink: true,
    state: { anchor: 'leadership' },
  },
  {
    displayedName: 'Executive search',
    link: ROUTES.services,
    anchorLink: true,
    state: { anchor: 'search' },
  },
];

export const HEADER_LINKS = [
  { displayedName: 'Vision', link: ROUTES.vision },
  { displayedName: 'Industry', link: ROUTES.industry },
  {
    displayedName: 'Services',
    link: ROUTES.services,
    linksList: SERVICES_LINKS,
  },
  { displayedName: 'People', link: ROUTES.people },
  {
    displayedName: 'Testimonials',
    link: ROUTES.root,
    anchorLink: true,
    state: { anchor: 'testimonials' },
  },
];

export const FOOTER_LINKS = [
  { displayedName: 'Vision', link: ROUTES.vision },
  { displayedName: 'Industry', link: ROUTES.industry },
  { displayedName: 'People', link: ROUTES.people },
  {
    displayedName: 'Testimonials',
    link: ROUTES.root,
    state: { anchor: 'testimonials' },
  },
];

export const mapAddressLink = 'https://maps.app.goo.gl/L49MEbE8AWfZ9nPb8';
