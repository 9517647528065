import React from 'react';

import MuiDialog from '@mui/material/Dialog';

const Dialog = React.forwardRef((props, ref) => {
  const { PaperProps, ...other } = props;

  return (
    <MuiDialog
      ref={ref}
      fullWidth
      PaperProps={{
        ...PaperProps,
        sx: {
          position: 'relative',
          maxHeight: 'calc( 100% - 32px )',
          borderRadius: 3,
          p: 3,
          ...PaperProps?.sx,
        },
      }}
      scroll="paper"
      {...other}
    />
  );
});

export default React.memo(Dialog);
