import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

const ContentHeaderSection = ({ subTitle, title, titleProps, subTitleProps, sectionStyles }) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} {...sectionStyles}>
      <Typography medium color="primary.main" {...subTitleProps}>
        {subTitle}
      </Typography>
      <Typography
        variant="h2"
        extraBold
        fontSize={{ mobile: 32, tablet: 56 }}
        lineHeight={{ mobile: '40px', tablet: '64px' }}
        {...titleProps}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default React.memo(ContentHeaderSection);

ContentHeaderSection.propTypes = {
  title: PropTypes.string,
  titleProps: PropTypes.object,
  subTitle: PropTypes.string,
  subTitleProps: PropTypes.object,
  sectionStyles: PropTypes.object,
};
