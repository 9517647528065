import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: replace with 'react-use'
const useScrollToAnchor = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.anchor) {
      setTimeout(() => {
        document
          .getElementById(location.state.anchor)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        navigate(location.pathname, { replace: true });
      }, 100);
    }
  }, [location, navigate]);

  return useMemo(() => ({ location, navigate }), [location, navigate]);
};

export default useScrollToAnchor;
