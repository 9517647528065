import React from 'react';

import IndustriesHeading from './IndustriesHeading';
import IndustriesInfo from './IndustriesInfo';
import IndustriesSectionWrapper from './IndustriesSectionWrapper';

const IndustriesSection = () => {
  return (
    <IndustriesSectionWrapper>
      <IndustriesHeading />
      <IndustriesInfo />
    </IndustriesSectionWrapper>
  );
};

export default IndustriesSection;
