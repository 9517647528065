import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { HeaderSection } from 'components/common/ContentHeaderSection';
import { MAX_WIDTH_CONTENT } from '@constants';
import { ROUTES } from 'routes';

const OurServicesHeader = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={{ mobile: 'flex-start', laptop: 'flex-end' }}
      maxWidth={MAX_WIDTH_CONTENT}
      width={1}
      mx="auto"
      flexDirection={{ mobile: 'column', laptop: 'row' }}
      gap={{ mobile: 6.25, laptop: 0 }}
    >
      <Box display="flex" flexDirection="column" gap={6.25} maxWidth={595}>
        <HeaderSection subTitle="Our services" title="HCC Executive redefines leadership" />
        <Typography medium color="text.simple">
          We are here to guide you through your career journey by offering tailored advice that
          aligns with your skills and aspirations, ensuring optimal growth as you navigate your
          professional path.
        </Typography>
      </Box>
      <Button to={ROUTES.services} size="fit-content" buttonTypes="outlined">
        Explore more
      </Button>
    </Box>
  );
};

export default OurServicesHeader;
