import React from 'react';

import Box from 'components/ui/Box';
import { MAX_WIDTH_CONTENT } from '@constants';

import BgImg from 'assets/images/home/bg-home.jpg';
import BgImgMobile from 'assets/images/home/bg-home-mobile.jpg';

const HeadingWrapper = (props) => {
  return (
    <Box
      component="section"
      display="inline-flex"
      flexDirection="column"
      justifyContent={{ mobile: 'flex-end' }}
      width="100%"
      minHeight="100vh"
      textAlign="center"
      backgroundColor="secondary.contrastText"
      color="secondary.main"
      pb={{ mobile: 12, laptop: 3 }}
      sx={{
        backgroundImage: { mobile: `url(${BgImgMobile})`, tablet: `url(${BgImg})` },
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: { mobile: '25%', tablet: '32%', laptop: 'unset' },
      }}
    >
      <Box maxWidth={MAX_WIDTH_CONTENT} mx="auto" width="100%" {...props} />
    </Box>
  );
};

export default HeadingWrapper;
