import React from 'react';

import SectionWrapper from './SectionWrapper';
import IndustriesHeader from './IndustriesHeader';
import IndustriesBody from './IndustriesBody';

const IndustriesSection = () => {
  return (
    <SectionWrapper backgroundColor="secondary.dark" wrapper="true">
      <IndustriesHeader />
      <IndustriesBody />
    </SectionWrapper>
  );
};

export default IndustriesSection;
