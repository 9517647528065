export const INDUSTRIES = {
  bpo: {
    title: 'BPO & Shared Services',
    iconName: 'bpo-services',
    text: 'Transform talent landscape to thrive in competitive business environment.',
    subText:
      "Through innovative approach to Executive Search and a deep understanding of industry dynamics, HCC ensures seamless integration of top-tier talent, enabling organizations in the SSC & BPO sectors to thrive in today's competitive business environment.",
  },
  consumer: {
    title: 'Consumer',
    iconName: 'consumer',
    text: 'Empower leaders to drive operational excellence and strategic vision.',
    subText:
      "Whether it's in marketing, supply chain or operations, our services ensure your Executive Team will be comprised of individuals who can keep pace with the fast-moving nature of the FMCG industry. We also will help you find & develop executives who possess operational expertise and strategic vision to drive revenue growth and market expansion in the Retail space.",
  },
  digital: {
    title: 'Digital & Technology',
    iconName: 'digital-technology',
    text: "Unlock company's potential with top-tier leadership.",
    subText:
      "We specialize in identifying and attracting skilled professionals who bring innovation and expertise to drive your IT corporate landscape forward. Whether you're a startup or an established tech giant, we ensure a seamless recruitment process that aligns with your organizational goals.",
  },
  commerce: {
    title: 'E-Commerce',
    iconName: 'e-commerce',
    text: 'Seize digital opportunities to craft exceptional user experiences.',
    subText:
      'The e-commerce sector demands leaders who can capitalize on digital opportunities and deliver exceptional customer experiences. Together, we shape leaders who are not only adaptive to change but also capable of leading transformative initiatives that propel e-commerce businesses to new heights of success.',
  },
  finance: {
    title: 'Finance & Banking',
    iconName: 'finance-banking',
    text: 'Lead the way with comprehensive approach to leadership.',
    subText:
      'From traditional consumer and comercial banks to investment banking institutions everyone in on the look out for high potentials. We have helped many financial services firms to successfully compete in the war for talent by providing effective, efficient and scalable sourcing solutions.',
  },
  health: {
    title: 'Health',
    iconName: 'health',
    text: 'Empower executives at the forefront of Pharma Excellence.',
    subText:
      'In the dynamic field of pharmaceuticals, securing the right talent & effective leadership is paramount - driving innovation, advancing medical breakthroughs and ultimately improving patient outcomes.',
  },
  industrial: {
    title: 'Industrial & Engineering',
    iconName: 'industrial-engineering',
    text: 'Enter the realm of precision and innovation with the best Talents.',
    subText:
      "We assist companies in finding & accelerating leaders who excel in driving operational excellence, quality assurance, and product innovation across diverse engineering-related industries, ensuring sustained growth and competitive advantage in today's dynamic market landscape.",
  },
  real_estate: {
    title: 'Real Estate & Construction',
    iconName: 'real-estate-construction',
    text: 'Leverage tailored solutions to redefine leadership.',
    subText:
      'We lead the way in sourcing top-tier executives and nurturing leadership talent across construction, property development, investment, brokerage and green building technology. With a proven track record of delivering results, our personalized strategies guarantee unmatched outcomes tailored to your unique needs. Unlock success with our specialized approach.',
  },
};

export const CHUNKED_INDUSTRIES = [
  [INDUSTRIES.bpo, INDUSTRIES.consumer],
  [INDUSTRIES.digital, INDUSTRIES.commerce],
  [INDUSTRIES.finance, INDUSTRIES.health],
  [INDUSTRIES.industrial, INDUSTRIES.real_estate],
];

export const INDUSTRIES_INFO = [
  INDUSTRIES.digital,
  INDUSTRIES.health,
  INDUSTRIES.commerce,
  INDUSTRIES.consumer,
  INDUSTRIES.industrial,
  INDUSTRIES.bpo,
  INDUSTRIES.real_estate,
  INDUSTRIES.finance,
];
