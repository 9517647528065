import React, { memo } from 'react';

import { TextField as MuiTextField } from '@mui/material';

import Collapse from 'components/ui/Collapse';
import { Fonts } from 'assets/theme';

const TextField = React.forwardRef((props, ref) => {
  const {
    size,
    inputProps,
    SelectProps,
    multiline,
    collapseHelperText,
    InputLabelProps,
    InputProps,
    sx,
    errors,
    helperText,
    ...other
  } = props;

  return (
    <MuiTextField
      ref={ref}
      variant="standard"
      inputProps={inputProps}
      multiline={multiline}
      FormHelperTextProps={{
        component: 'div',
      }}
      helperText={
        <Collapse component="span" in={Boolean(errors?.message ?? helperText ?? '')}>
          {errors?.message ?? helperText}&nbsp;
        </Collapse>
      }
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 1,
        '& .MuiInput-root': {
          mt: 0.75,
          p: 0,
          minWidth: 150,
        },
        '& .MuiFormHelperText-root': {
          fontSize: 14,
          lineHeight: '20px',
          color: 'red.main',
          mt: 0.75,
        },
        '&  .MuiInputLabel-root.Mui-focused': {
          color: 'text.primary',
        },
        ...sx,
      }}
      InputLabelProps={{
        shrink: true,
        sx: {
          color: 'text.primary',
          position: 'static',
          transform: 'none',
          fontSize: 14,
          lineHeight: '20px',
          zIndex: 1,
          fontFamily: Fonts.SemiBold,
          ...InputLabelProps?.sx,
        },
      }}
      InputProps={{
        sx: {
          border: 1,
          borderRadius: 2,
          borderColor: 'gray.light',
          '& .MuiInput-input': {
            px: 1.5,
            py: 1,
            borderRadius: 2,
            height: 32,
            boxShadow: 1,
            ...(multiline && {
              px: 1,
              height: '134px !important',
              maxHeight: 134,
              pt: 1,
              overflow: 'auto !important',
            }),
            '&:focus': {
              boxShadow: 2,
              borderColor: 'purple.light',
            },
            ...(errors && {
              borderColor: 'red.light',
              '&:focus': {
                boxShadow: 3,
                borderColor: 'red.light',
              },
            }),
            '&:disabled': {
              borderColor: 'gray.contrastText',
              color: 'secondary.light',
              boxShadow: 1,
            },
          },
          ...(errors && {
            borderColor: 'red.light',
            '&:focus': {
              boxShadow: 3,
              borderColor: 'red.light',
            },
          }),
          '& input::placeholder': {
            color: 'gray.main',
            opacity: 1,
          },
          '&:before': { display: 'none' },
          '&:after': { display: 'none' },
          ...InputProps?.sx,
        },
      }}
      {...other}
    />
  );
});

export default memo(TextField);
