import React from 'react';

import Box from 'components/ui/Box';
import { MAX_WIDTH_CONTENT } from '@constants';

const WrapperContent = (props) => (
  <Box maxWidth={MAX_WIDTH_CONTENT} mx="auto" overflow="hidden">
    <Box
      display="flex"
      px={{ mobile: 2.5, tablet: 7.5, laptop: '7%' }}
      py={{ mobile: 2.5, tablet: 7 }}
      flexDirection="column"
      backgroundColor="secondary.main"
      {...props}
    />
  </Box>
);

const SectionWrapper = (props) => {
  if (props.wrapper)
    return (
      <Box backgroundColor="secondary.dark">
        <WrapperContent {...props} />
      </Box>
    );
  return <WrapperContent {...props} />;
};

export default React.memo(SectionWrapper);
