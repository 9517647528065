import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';

import { FormHeaderText } from './constants';

const ModalFormHeader = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize={20} lineHeight='28px' extraBold>
        {FormHeaderText.title}
      </Typography >
      <Typography fontSize={14} lineHeight='20px' mt={1} medium>
        {FormHeaderText.subTitle}
      </Typography>
    </Box>
  );
};

export default ModalFormHeader;
