import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Image from 'components/ui/Image';
import { Fonts } from 'assets/theme';
import { useIsTabletResolution } from 'hooks';
import { useIsMobile } from 'hooks';

import { TESTIMONIALS } from 'pages/Landing/constants';
import { CarouselDots } from 'components/common/CarouselDots';

const TestimonialsCarousel = () => {
  const isTabletResolution = useIsTabletResolution();
  const isMobile = useIsMobile();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const sidesNumber = isMobile ? 1 : isTabletResolution ? 2 : 3;

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      drag: true,
      mode: 'free',
      renderMode: 'performance',
      slides: { perView: sidesNumber, spacing: 0 },

      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [
      (slider) => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 3000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            setLoaded(true);
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );

  return (
    <Box
      width={1}
      sx={{
        '.dots': {
          display: 'flex',
          padding: '40px 0 10px',
          justifyContent: 'center',
        },
        '.dot': {
          flex: '0 0 auto',
          border: 'none',
          width: 10,
          height: 10,
          backgroundColor: 'secondary.dark',
          opacity: 0.3,
          borderRadius: '50%',
          margin: ' 0 7px',
          padding: 0,
          cursor: 'pointer',
        },
        '.dot:focus': {
          outline: 'none',
        },
        '.dot.active': {
          backgroundColor: 'primary.main',
          opacity: 1,
        },
      }}
    >
      <Box
        ref={sliderRef}
        className="keen-slider"
        sx={{
          '&:not([data-keen-slider-disabled])': {
            overflow: 'visible',
          },
        }}
      >
        {TESTIMONIALS.map((item) => (
          <Box className="keen-slider__slide" component="article" key={item.id} pr={2.5}>
            <Box
              itemScope
              itemType="https://schema.org/Person"
              height="100%"
              backgroundColor="gray.dark"
              borderRadius="8px"
              position="relative"
              px={2.5}
              pt={2.5}
              pb={12}
              transition="all 0.2s ease-out"
              sx={{
                '&:hover': { backgroundColor: 'gray.background', transition: 'all 0.2s ease-out' },
              }}
            >
              <Box>
                <Typography
                  fontFamily={Fonts.Medium}
                  fontSize={{ mobile: '15px', laptop: '17px' }}
                  lineHeight={1.6}
                >
                  “{item.text}”
                </Typography>
              </Box>
              <Box pt={2}>
                <Box itemProp="name" fontSize={{ mobile: '12px', laptop: '16px' }} lineHeight={1.4}>
                  {item.authorName}
                </Box>
                <Box
                  content="span"
                  itemProp="jobTitle"
                  color="text.disabled"
                  fontSize={{ mobile: '12px', laptop: '16px' }}
                  lineHeight={1.2}
                >
                  {item.authorPosition}
                </Box>
              </Box>
              <Box
                height="96px"
                position="absolute"
                bottom={20}
                left={20}
                display="flex"
                alignItems="flex-end"
              >
                <Box
                  itemProp="worksFor"
                  itemType="https://schema.org/Organization"
                  color="gray.text"
                  fontFamily={Fonts.ExtraBold}
                  fontSize={{ mobile: '28px', laptop: '32px' }}
                  sx={{
                    '& img': {
                      width: 'auto !important',
                      display: 'block',
                      maxWidth: '150px',
                      height: '64px !important',
                      borderRadius: '0px !important',
                      objectFit: 'contain',
                    },
                  }}
                >
                  {item.logo ? (
                    <Image
                      src={require(`assets/images/customers-logo/${item.logo}-gray.svg`)}
                      alt={item.name}
                      width="150"
                      height="36"
                      fit="contain"
                      wrapperStyle={{ width: '150px', maxHeight: '64px', display: 'block' }}
                    />
                  ) : (
                    item.title
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {loaded && instanceRef.current && (
        <CarouselDots
          instanceRef={instanceRef}
          currentSlide={currentSlide}
          slidesCount={sidesNumber}
        />
      )}
    </Box>
  );
};

export default TestimonialsCarousel;
