import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';
import Image from 'components/ui/Image';
import Icon from 'components/ui/Icon';
import { PEOPLE } from '../constants';

const SocialNetworksIcon = ({ className }) => (
  <Icon
    className={className}
    fontSize="1.25rem"
    height={22}
    sx={{
      transition: (theme) => theme.transitions.create('opacity', { duration: 300 }),
      opacity: 1,
    }}
  >
    <Typography variant="span" className="path1" />
    <Typography variant="span" className="path2" />
  </Icon>
);

const ParagraphText = ({ text }) =>
  text.map((item) => (
    <Typography key={item} medium color="text.simple" py={1.5} lineHeight={1.5}>
      {item}
    </Typography>
  ));

const PeopleInfo = () => (
  <Box
    display="flex"
    flexDirection="column"
    gap={4}
    maxWidth="1008px"
    mx="auto"
    itemScope
    itemType="https://schema.org/Person"
  >
    {PEOPLE.map((person) => (
      <Box
        key={person.id}
        display={{ laptop: 'flex' }}
        backgroundColor="gray.dark"
        borderRadius="8px"
      >
        <Box px={2.5} py={2.5} maxWidth="416px" width={1} flex="0 0 auto" mx="auto">
          <Image src={person.image} fit="contain" alt={person.name} />
        </Box>
        <Box px={3.75} py={3.75} position="relative">
          <Typography variant="h3" fontSize={24} lineHeight={1.35} extraBold itemProp="name">
            {person.name}
          </Typography>
          <Typography medium color="text.simple" pb={2} itemProp="jobTitle">
            {person.job}
          </Typography>
          <ParagraphText text={person.text} />
          <Box
            position={{ laptop: 'absolute' }}
            bottom={30}
            left={30}
            right={30}
            display="flex"
            justifyContent="space-between"
          >
            <a
              href={`mailto:${person.email}`}
              itemProp="email"
              content={person.email}
              title="Send email"
            >
              <Button buttonTypes="noBackground" size="fit-content">
                Contact
              </Button>
            </a>
            <Box
              display="flex"
              alignItems="center"
              fontFamily="hcc-icons !important"
              sx={{
                '*': { fontFamily: 'hcc-icons !important' },
                '& > a:hover .path1::before': { color: '#006c51' },
              }}
            >
              <a
                href={person.socialsLink}
                title="to LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialNetworksIcon className="icon icon-linkedin-icon" />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
);

export default PeopleInfo;
