import { INDUSTRIES } from '@constants';

export const CUSTOMERS_LOGO = [
  { name: 'Lufthansa', imageName: 'lufthansa' },
  { name: 'Capgemini', imageName: 'capgemini' },
  { name: 'Schneider Electric', imageName: 'schneider-electric' },
  { name: 'AmRest', imageName: 'amrest' },
  { name: 'Strabag', imageName: 'strabag' },
  { name: 'State Street', imageName: 'state-street' },
  { name: 'BNP Paribas', imageName: 'bnp-paribas' },
];

export const IndustriesInfo = [
  INDUSTRIES.bpo,
  INDUSTRIES.consumer,
  INDUSTRIES.digital,
  INDUSTRIES.commerce,
  INDUSTRIES.finance,
  INDUSTRIES.health,
  INDUSTRIES.industrial,
  INDUSTRIES.real_estate,
];

export const OurServicesTextVariations = {
  career: 'career',
  leadership: 'leadership',
  executive: 'executive',
};

export const OurServicesTextImages = {
  career: require('assets/images/services-page/career-advisory.jpg'),
  leadership: require('assets/images/services-page/leadership-development.jpg'),
  executive: require('assets/images/services-page/executive-search.jpg'),
};

export const OUR_SERVICES = [
  {
    id: 1,
    title: 'Career Advisory',
    text: "Whether you're an emerging leader or a seasoned executive, we provide personalized guidance to help you make informed career decisions. From optimizing your CV to providing insights on industry trends, we empower you to shape a career that aligns with your aspirations.",
    subTitle:
      'Navigate the complexities of your professional journey with our expert career advisory services.',
    type: OurServicesTextVariations.career,
  },
  {
    id: 2,
    title: 'Leadership Development',
    text: 'Our experienced coaches work closely with individuals and teams to identify strengths, address challenges, and foster a culture of continuous improvement. From emerging leaders to C-suite executives, our leadership development services are designed to enhance skills, drive innovation and maximize organizational impact.',
    subTitle: 'Elevate your leadership potential with our tailored development programs.',
    type: OurServicesTextVariations.leadership,
  },
  {
    id: 3,
    title: 'Executive Search',
    text: "We specialize in identifying and attracting leaders who not only possess the right skills and experience but also align with your organization's values and vision. Whether you're filling a key leadership position or building a high-performing team, our executive search expertise ensures that you have access to the best talent in the market.",
    subTitle: 'Discover unparalleled talent through our Executive Search services.',
    type: OurServicesTextVariations.executive,
  },
];

export const TESTIMONIALS = [
  {
    id: 1,
    title: 'CAPGEMINI',
    text: 'For any organization seeking to build a team of top-tier talent & drive success in the information technology, consulting & outsourcing services sector, I wholeheartedly endorse collaborating with HCC.',
    authorName: 'Monika Chajdacka',
    authorPosition: 'HR Manager',
    logo: 'capgemini',
  },
  {
    id: 2,
    title: 'Schneider Electric',
    text: 'I would recommend HCC as a talent sourcing provider going an extra mile to meet our expectations. The communication with HCC recruitment advisors was adapted to company needs and met our requirements.',
    authorName: 'Anna Michalak',
    authorPosition: 'HR BP for Global Functions',
    logo: 'schneider-electric',
  },
  {
    id: 3,
    title: 'Strabag',
    text: 'HCC proved to be a trustworthy, determined, and client-focused business partner. We recommend HCC to every company looking for a reliable supplier of recruitment services.',
    authorName: 'Bożena Czekajska',
    authorPosition: 'Director People & Culture',
    logo: 'strabag',
  },
  {
    id: 4,
    title: 'State Street',
    text: 'HCC has supported us in recruitment within finance & IT areas in SSC field. They delivered candidates in a timely manner, showing their deep understanding of our core values… effectiveness of consultants makes them recommendable partners.',
    authorName: 'Marta Waligórska',
    authorPosition: 'Talent Acquisition Manager',
    logo: 'state-street',
  },
  {
    id: 5,
    title: 'AmRest',
    text: 'HCC became more than our recruitment partner they became ambassador of our corporate culture and values embracing our "Every Thing is Possible" approach.',
    authorName: 'Anna Selwakowska',
    authorPosition: 'Senior Recruitment Manager',
    logo: 'amrest',
  },
  {
    id: 6,
    title: 'Lufthansa',
    text: 'HCC has capability to identify, attract & retain the best professionals on the marketplace. Their added value can be measured by the speed in which they provide suitable quality candidates.',
    authorName: 'Anna Mali',
    authorPosition: 'HR Manager',
    logo: 'lufthansa',
  },
  {
    id: 7,
    title: 'NatWest Group',
    text: 'HCC development program encouraged me to reflect deeply on my current position, my aspirations, and how I can leverage my unique strengths and address areas for improvement in my leadership role.',
    authorName: 'Krzysztof Walczak',
    authorPosition: 'L&D Team Leader',
  },
  {
    id: 8,
    title: 'Grant Thornton',
    text: "Program and approach was particularly commendable. With help from HCC I've already been able to implement the ideas in my professional life. Without a doubt, I consider the program to be a worthwhile and good investment of time.",
    authorName: 'Edward Nieboj',
    authorPosition: 'Managing Partner',
  },
  {
    id: 9,
    title: 'Incora',
    text: 'HCC has been an invaluable partner for us. Their unique methods and tools for training, coupled with insightful guest speakers, have truly enriched our learning experience.',
    authorName: 'Agata Kopydłowska',
    authorPosition: 'Talent Acquisition Manager',
  },
  {
    id: 10,
    title: 'Newell Brands',
    text: "The program facilitated a deeper understanding of my 'default settings' and helped me articulate my leadership aspirations. Highly recommend HCC and their leadership development program.",
    authorName: 'Magdalena Jankowska',
    authorPosition: 'Consumer Services Manager',
  },
  {
    id: 11,
    title: 'Newell Brands',
    text: "I discovered HCC through ABSL, I've engaged in several initiatives. HCC team's preparedness & positivity foster an excellent learning environment. While the content is consistently interesting and practical.",
    authorName: 'Bartosz Nowak',
    authorPosition: 'Senior Manager, Global Consumer Services',
  },
  {
    id: 12,
    title: 'Vapiano',
    text: 'I am delighted to warmly recommend HCC as an exceptional provider of development services for top-tier managers. Their program offers an extraordinary, unique approach that is unmatched in the market.',
    authorName: 'Ewa Hołowczak',
    authorPosition: 'Head of HR',
  },
  {
    id: 13,
    title: 'Siemens',
    text: "HCC's personnel advisors take the time to get to know an organization& culture, become fully briefed on the particular requirements of each assignment, and consequently do their best to provide high-caliber candidates.",
    authorName: 'Wiesława Czarnecka-Stańczak',
    authorPosition: 'People & Organization Director',
  },
  {
    id: 14,
    title: 'CBRE',
    text: 'HCC not just a talent sourcing provider but a true recruitment partner going an extra mile to meet client expectations.',
    authorName: 'Dominika Brodacka',
    authorPosition: 'Talent Acquisition Lead',
  },
];
