import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Image from 'components/ui/Image';
import Box from 'components/ui/Box';
import Button from 'components/ui/Button';
import Dialog from 'components/ui/Dialog';
import IconButton from 'components/ui/IconButton';

import ModalFormHeader from './ModalFormHeader';
import ModalContactForm from './ModalContactForm';

const ModalContactFormWrapper = ({ buttonProps, buttonText = 'Contact us' }) => {
  const [isOpenModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => setOpenModal(!isOpenModal), [isOpenModal]);

  return useMemo(
    () => (
      <>
        <Button onClick={toggleModal} size="fitContent" {...buttonProps}>
          {buttonText}
        </Button>
        <Dialog
          open={isOpenModal}
          PaperProps={{ sx: { maxWidth: { mobile: 'none', tablet: 600, laptop: 750 } } }}
        >
          <IconButton
            iconName="close"
            onClick={toggleModal}
            sx={{ position: 'absolute', top: 5, right: 10 }}
          />
          <Image
            src={require('assets/images/bg-modal-pattern-decorative.png')}
            position="absolute"
            style={{ top: 0, left: 0, width: 175, height: 200 }}
          />
          <Box position="relative" zIndex={1}>
            <ModalFormHeader />
            <ModalContactForm closeModal={toggleModal} />
          </Box>
        </Dialog>
      </>
    ),
    [isOpenModal, toggleModal, buttonProps, buttonText],
  );
};

export default ModalContactFormWrapper;

ModalContactFormWrapper.propTypes = {
  buttonProps: PropTypes.object,
  buttonText: PropTypes.string,
};
