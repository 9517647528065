export const serviceContent = [
  {
    id: 'career',
    direction: 'row',
    image: require('assets/images/services-page/career-advisory.jpg'),
    text: "Whether you're an emerging leader or a seasoned executive, we provide personalized guidance to help you make informed career decisions. From optimizing your CV to providing insights on industry trends, we empower you to shape a career that aligns  with your aspirations.",
    title: 'Career Advisory',
    textSubtitle:
      'Navigate the complexities of your professional journey with our expert career advisory services.',
  },
  {
    id: 'leadership',
    direction: 'row-reverse',
    image: require('assets/images/services-page/leadership-development.jpg'),
    text: 'Our experienced coaches work closely with individuals and teams to identify strengths, address challenges, and foster a culture of continuous improvement. From emerging leaders to C-suite executives, our leadership development services are designed to enhance skills, drive innovation and maximize organizational impact.',
    title: 'Leadership Development',
    textSubtitle: 'Elevate your leadership potential with our tailored development programs.',
  },
  {
    id: 'search',
    direction: 'row',
    image: require('assets/images/services-page/executive-search.jpg'),
    text: "We specialize in identifying and attracting leaders who not only possess the right skills and experience but also align with your organization's values and vision. Whether you're filling a key leadership position or building a high-performing team, our executive search expertise ensures that you have access to the best talent in the market.",
    title: 'Executive Search',
    textSubtitle: 'Discover unparalleled talent through our Executive Search services.',
  },
];

export const MAX_WIDTH_SERVICE_CONTENT = 1216;
