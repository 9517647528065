import React from 'react';

import TestimonialsBody from './TestimonialsBody';
import TestimonialsHeading from './TestimonialsHeading';
import TestimonialsWrapper from './TestimonialsWrapper';

const Testimonials = () => {
  return (
    <TestimonialsWrapper>
      <TestimonialsHeading />
      <TestimonialsBody />
    </TestimonialsWrapper>
  );
};

export default Testimonials;
