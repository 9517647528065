import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { HeaderSection } from 'components/common/ContentHeaderSection';

const IndustriesHeading = () => (
  <Box maxWidth="600px" mx="auto" textAlign="center" pb={5}>
    <HeaderSection
      title="Discover our unparalleled industry - related experience"
      subTitle="Our industries"
      subTitleProps={{ textAlign: 'center' }}
      titleProps={{ textAlign: 'center' }}
    />
    <Typography medium color="text.simple" pt={3.5} pb={2.5}>
      At HCC we understand that every industry is unique and success demands leaders who intimately
      grasp its nuances. Our unwavering commitment to industry-specific expertise sets us apart,
      ensuring your organization partners with executives who not only lead but thrive in your
      sector.
    </Typography>
  </Box>
);

export default IndustriesHeading;
