import React from 'react';

import SectionWrapper from './SectionWrapper';
import OurServicesHeader from './OurServicesHeader';
import OurServiceBody from './OurServiceBody';

const OurServices = () => {
  return (
    <SectionWrapper>
      <OurServicesHeader />
      <OurServiceBody />
    </SectionWrapper>
  );
};

export default OurServices;
