import React, { useMemo } from 'react';

import Box from 'components/ui/Box';
import Button from 'components/ui/Button';
import When from 'components/ui/When';
import { useIsTabletResolution } from 'hooks';

import IndustriesList from './IndustriesList';
import { MobileOurIndustries } from '../mobile';
import { ROUTES } from 'routes';

const IndustriesBody = () => {
  const isTabletResolution = useIsTabletResolution();

  return useMemo(
    () => (
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexWrap="wrap"
          gap={4}
          justifyContent="center"
          maxWidth={1216}
          mx={{ mobile: 0, laptop: 'auto' }}
          mt={{ mobile: 2.5, tablet: 6.25 }}
          width={1}
          ml={{ laptop: 'auto' }}
        >
          <When condition={isTabletResolution} disabledWrapper fallback={<IndustriesList />}>
            <MobileOurIndustries />
          </When>
        </Box>
        <Button to={ROUTES.industry} sx={{ mt: 6.75, mx: 'auto' }} size="fit-content">
          Learn more
        </Button>
      </Box>
    ),
    [isTabletResolution],
  );
};

export default IndustriesBody;
