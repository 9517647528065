import React from 'react';
import { Link } from 'react-router-dom';

import Image from 'components/ui/Image';
import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Button from 'components/ui/Button';
import { HeaderSection } from 'components/common/ContentHeaderSection';

import { ROUTES } from 'routes';

const OurPeopleBody = () => {
  return (
    <Box
      display="flex"
      gap={{ mobile: 3.75, tablet: 7.5, laptop: 0 }}
      justifyContent="space-between"
      flexDirection={{ mobile: 'column', laptop: 'row' }}
    >
      <Image
        src={require('assets/images/our-people.png')}
        style={{ maxHeight: 623, minWidth: 320 }}
        wrapperStyle={{ height: 'auto' }}
      />
      <Box
        display="flex"
        flexDirection="column"
        maxWidth={{ mobile: 1, laptop: '50%' }}
        pl={{ laptop: '8%' }}
      >
        <HeaderSection
          subTitle="Our people"
          title="Discover the driving force behind our success"
        />
        <Typography medium color="text.simple" mt={6.25}>
          Our diverse team brings a wealth of experience, enabling us to provide customized
          solutions that drive business success. <br />
          <br />
          By combining our expertise in people development, psychology, coaching, and talent
          assessment, we unlock potential and cultivate excellence in leadership at every level.{' '}
          <br />
          <br />
          From personalized talent acquisition strategies, through career advisory to comprehensive
          leadership development programs, we are dedicated to helping businesses thrive in today's
          competitive landscape.
        </Typography>
        <Link to={ROUTES.people}>
          <Button buttonTypes="link" size="fit-content" sx={{ mt: { mobile: 3.75, tablet: 6.25 } }}>
            Meet our team
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default OurPeopleBody;
