import React from 'react';
import MuiImage from 'mui-image';

const Image = ({ width, height, src = '', alt, style, borderRadius = 8, ...other }) => {
  return (
    <MuiImage
      duration={250}
      src={src}
      alt={alt}
      width={width}
      height={height}
      wrapperStyle={other.wrapperStyle ?? { width: 'auto' }}
      style={{
        minWidth: width,
        minHeight: height,
        borderRadius,
        ...style,
      }}
      {...other}
    />
  );
};

export default React.memo(Image);
