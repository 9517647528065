import React from 'react';

import Box from 'components/ui/Box';
import TestimonialsCarousel from './TestimonialsCarousel';

const TestimonialsBody = () => {
  return (
    <Box px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}>
      <TestimonialsCarousel />
    </Box>
  );
};

export default TestimonialsBody;
