import React, { useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

import Box from 'components/ui/Box';
import { useIsMobile } from 'hooks';

import { CarouselDots } from 'components/common/CarouselDots';
import IndustriesChunkedList from './IndustriesChunkedList';

const MobileOurIndustries = () => {
  const isMobile = useIsMobile();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: false,
    mode: 'free',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    slides: { perView: isMobile ? 1.03 : 2.5, spacing: 0 },
    created() {
      setLoaded(true);
    },
  });

  return (
    <Box
      width={1}
      sx={{
        '.dots': {
          display: 'flex',
          padding: '20px 0',
          justifyContent: 'center',
        },
        '.dot': {
          border: 'none',
          width: 10,
          height: 10,
          backgroundColor: 'secondary.main',
          opacity: 0.3,
          borderRadius: '50%',
          margin: ' 0 10px',
          padding: 0,
          cursor: 'pointer',
        },
        '.dot:focus': {
          outline: 'none',
        },
        '.dot.active': {
          backgroundColor: 'primary.main',
          opacity: 1,
        },
        '.keen-slider:not([data-keen-slider-disabled])': { overflow: 'visible' },
      }}
    >
      <Box ref={sliderRef} className="keen-slider">
        <IndustriesChunkedList />
      </Box>
      {loaded && instanceRef.current && (
        <CarouselDots
          instanceRef={instanceRef}
          currentSlide={currentSlide}
          slidesCount={isMobile ? 1 : 2}
        />
      )}
    </Box>
  );
};

export default MobileOurIndustries;
