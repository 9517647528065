import React from 'react';

import Box from 'components/ui/Box';
import { MAX_WIDTH_CONTENT } from '@constants';

const TestimonialsWrapper = (props) => (
  <Box overflow="hidden">
    <Box
      maxWidth={MAX_WIDTH_CONTENT}
      width="100%"
      mx="auto"
      pt={{ mobile: 2, tablet: 6.5, laptop: 12.5 }}
      {...props}
    />
  </Box>
);

export default TestimonialsWrapper;
