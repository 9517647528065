import React from 'react';

import Box from 'components/ui/Box';
import Image from 'components/ui/Image';
import { MAX_WIDTH_CONTENT } from '@constants';

import { CUSTOMERS_LOGO } from '../constants';

const HeadingLogo = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      maxWidth={MAX_WIDTH_CONTENT}
      pl={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
      mx="auto"
      sx={{
        '& ::-webkit-scrollbar': {
          width: '4px',
          height: '4px',
        },
        '& ::-webkit-scrollbar-thumb': {
          backgroundColor: 'gray.dark',
          borderRadius: '2px',
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'primary.light',
        },
      }}
    >
      <Box
        fontSize={{ mobile: '12px', tablet: '16px' }}
        pr={{ mobile: 3, tablet: 5 }}
        width={{ mobile: '96px', tablet: '132px' }}
      >
        Trusted by:
      </Box>
      <Box
        overflow="auto"
        pb={1}
        width={{ mobile: 'calc(100% - 96px)', tablet: 'calc(100% - 132px)' }}
      >
        <Box
          content="ul"
          display="flex"
          justifyContent="space-between"
          pr={{ mobile: 2, tablet: 7.5, laptop: 12.5 }}
          sx={{ listStyle: 'none' }}
        >
          {CUSTOMERS_LOGO.map((item) => (
            <Box
              content="li"
              key={item.name}
              pr={{ mobile: 3, tablet: 4 }}
              sx={{
                '& img': {
                  width: 'auto !important',
                  display: 'block',
                  maxWidth: '110px',
                  height: '36px !important',
                  borderRadius: '0px !important',
                  objectFit: 'contain',
                },
              }}
            >
              <Image
                src={require(`assets/images/customers-logo/${item.imageName}.svg`)}
                alt={item.name}
                width="100"
                height="36"
                fit="contain"
                wrapperStyle={{ width: 'unset', height: '36px', display: 'block' }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default HeadingLogo;
