import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import { HeaderSection } from 'components/common/ContentHeaderSection';

const IndustriesHeader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth={696}
      mx="auto"
      gap={6.25}
    >
      <HeaderSection
        title="We understand that every industry is unique"
        subTitle="Our industries"
        subTitleProps={{ sx: { textAlign: 'center' } }}
        titleProps={{ sx: { color: 'secondary.main', textAlign: 'center' } }}
      />
      <Typography textAlign="center" semiBold color="secondary.main">
        Our unwavering commitment to industry-specific expertise sets us apart, ensuring your
        organization partners with executives who not only lead but thrive in your sector.
      </Typography>
    </Box>
  );
};

export default IndustriesHeader;
