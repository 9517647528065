import React, { useCallback } from 'react';
import MuiButton from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

import { Fonts } from 'assets/theme';

const ButtonTypes = {
  main: {
    backgroundColor: 'primary.main',
    color: 'secondary.main',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'primary.light',
    },
    '&:active': {
      backgroundColor: 'primary.dark',
    },
    '&:disabled': {
      backgroundColor: 'primary.contrastText',
    },
  },
  outlined: {
    color: 'primary.main',
    border: 1,
    borderColor: 'primary.main',
    backgroundColor: 'secondary.main',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'secondary.main',
      color: 'primary.light',
    },
    '&:active': {
      backgroundColor: 'secondary.main',
      color: 'primary.active',
    },
  },
  noBackground: {
    color: 'primary.main',
    border: 1,
    borderColor: 'primary.main',
    backgroundColor: 'transparent',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'primary.light',
    },
    '&:active': {
      backgroundColor: 'secondary.main',
      color: 'primary.active',
    },
  },
  text: {
    border: 1,
    borderColor: 'gray.contrastText',
    backgroundColor: 'secondary.main',
    '&:hover': {
      backgroundColor: 'secondary.light',
    },
    '&:active': {
      backgroundColor: 'action.active',
    },
  },
  link: {
    color: 'primary.main',
    border: 'none',
    borderBottom: 1,
    borderRadius: 0,
    px: 0,
    borderColor: 'primary.main',
    backgroundColor: 'transparent',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'primary.light',
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: 'primary.active',
    },
  },
};

const highnessType = {
  high: {
    height: 48,
    p: '12px 24px',
    fontSize: 16,
    lineHeight: '24px',
  },
  medium: {
    height: 40,
    p: '8px 16px',
    fontSize: 16,
    lineHeight: '24x',
  },
  small: {
    height: 32,
    p: '6px 12px',
    fontSize: 14,
    lineHeight: '20px',
  },
};

const ButtonSizes = {
  main: 1,
  fitContent: 'fit-content',
};

const Button = React.forwardRef((props, ref) => {
  const {
    sx,
    size = 'main',
    highness = 'high',
    to,
    onClick,
    buttonTypes = 'main',
    ...other
  } = props;
  const navigate = useNavigate();

  const handleClick = useCallback(
    (event) => {
      onClick?.(event);
      to && navigate(to);
    },
    [navigate, onClick, to],
  );

  return (
    <MuiButton
      disableRipple
      sx={{
        width: ButtonSizes[size] ?? size,
        fontFamily: Fonts.SemiBold,
        borderRadius: 2,
        boxShadow: 'none !important',
        ...highnessType[highness],
        ...ButtonTypes[buttonTypes],
        ...sx,
      }}
      ref={ref}
      color="primary"
      onClick={handleClick}
      variant="contained"
      {...other}
    />
  );
});

export default React.memo(Button);
