import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from 'components/ui/Box';
import Image from 'components/ui/Image';
import { MAX_WIDTH_CONTENT } from '@constants';
import { ROUTES } from 'routes/config';

import { FOOTER_LINKS, SERVICES_LINKS, mapAddressLink } from '../constants';

const TextLabel = ({ text }) => (
  <Box component="span" color="white.light" display="inline-block" fontSize="14px" pb={1}>
    {text}
  </Box>
);

TextLabel.propTypes = {
  text: PropTypes.string,
};

const FooterTop = () => (
  <Box
    display={{ tablet: 'flex' }}
    flexWrap={{ tablet: 'wrap', laptop: 'nowrap' }}
    maxWidth={MAX_WIDTH_CONTENT}
    mx="auto"
    my={0}
    pb={{ tablet: 7.5 }}
    px={{ mobile: 2, tablet: 7.5, laptop: '7%' }}
  >
    <Box pb={{ mobile: 6, tablet: 7.5, laptop: 0 }} width={{ tablet: '100%', laptop: '30%' }}>
      <NavLink to={ROUTES.root} title="Landing">
        <Image
          src={require('assets/images/hcc-logo-white.svg').default}
          alt="HCC"
          width="152"
          height="25"
          borderRadius={0}
        />
      </NavLink>
    </Box>

    <Box
      itemScope
      itemType="http://schema.org/LocalBusiness"
      width={{ tablet: '50%', laptop: '40%' }}
    >
      <Box display={{ laptop: 'flex' }}>
        <Box width={{ laptop: '50%' }} pb={{ mobile: 4, tablet: 6, laptop: 0 }} pr={{ tablet: 3 }}>
          <TextLabel text="Address" />
          <Box
            itemProp="address"
            itemScope
            itemType="http://schema.org/PostalAddress"
            fontSize="14px"
            lineHeight={1.45}
          >
            <a href={mapAddressLink} target="_blank" rel="noreferrer nofollow">
              <Box component="span" itemProp="streetAddress">
                Residence Atelier <br />
                ul. Bagno 2, lok 78, klatka B,
                <br />
                II piętro,
              </Box>
              <Box component="span" itemProp="postalCode">
                00-112
              </Box>{' '}
              <Box component="span" itemProp="addressLocality">
                Warszawa
              </Box>
            </a>
          </Box>
        </Box>
        <Box width={{ laptop: '50%' }} pb={{ mobile: 4, tablet: 0 }} pr={{ tablet: 3 }}>
          <TextLabel text="Contact" />
          <Box
            itemProp="contactPoint"
            itemScope
            itemType="http://schema.org/ContactPoint"
            fontSize="14px"
            lineHeight={1.45}
          >
            <Box>
              <a
                href="tel:+48224144300"
                target="_blank"
                rel="noreferrer nofollow"
                itemProp="telephone"
                content="+48224144300"
              >
                +48 (22) 41 44 300
              </a>
            </Box>
            <Box>
              <a
                href="mailto:office@hcconsulting.pl"
                itemProp="email"
                content="office@hcconsulting.pl"
                rel="noreferrer nofollow"
              >
                office@hcconsulting.pl
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>

    <Box component="nav" display={{ laptop: 'flex' }} width={{ tablet: '50%', laptop: '30%' }}>
      <Box pb={{ mobile: 4, tablet: 6, laptop: 0 }} width={{ laptop: '50%' }}>
        <TextLabel text="Menu" />
        <Box component="ul" fontSize="14px">
          {FOOTER_LINKS.map((item) => (
            <Box component="li" key={item.link} lineHeight={1.5} pb={1}>
              <NavLink to={item.link} state={item.state ?? null}>
                {item.displayedName}
              </NavLink>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width={{ laptop: '50%' }} pb={{ mobile: 4, tablet: 0 }}>
        <TextLabel text="Services" />
        <Box component="ul" fontSize="14px">
          {SERVICES_LINKS.map((item) => (
            <Box component="li" key={item.displayedName} lineHeight={1.5} pb={1}>
              <Link to={ROUTES.services} state={item.state}>
                {item.displayedName}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  </Box>
);

export default FooterTop;
