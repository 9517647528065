import React from 'react';

import OurVisionBody from './OurVisionBody';
import SectionWrapper from './SectionWrapper';

const OurVision = () => {
  return (
    <SectionWrapper>
      <OurVisionBody />
    </SectionWrapper>
  );
};

export default OurVision;
