import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Icon from 'components/ui/Icon';

import { INDUSTRIES_INFO } from '@constants';

const IndustriesInfo = () => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" gap={3} width={1} mx="auto">
      {INDUSTRIES_INFO.map((industry) => (
        <Box
          component="article"
          key={industry.title}
          width={{ mobile: 1, tablet: 'calc(50% - 0.75rem)', laptop: 'calc(33.333% - 1rem)' }}
          backgroundColor="gray.dark"
          borderRadius="8px"
          position="relative"
          px={{ mobile: 2, tablet: 3, laptop: 4 }}
          pt={{ mobile: 2, tablet: 3, laptop: 4 }}
          pb={12}
        >
          <Typography variant="h3" fontSize="24px" extraBold>
            {industry.title}
          </Typography>
          <Typography medium lineHeight={1.6} color="primary.main" pt={2.5} pb={1}>
            {industry.text}
          </Typography>
          <Typography medium lineHeight={1.6} color="text.simple" pb={2.5}>
            {industry.subText}
          </Typography>
          <Icon
            name={industry.iconName}
            position="absolute"
            bottom={30}
            width="fit-content"
            fontSize={55}
            height={58}
            sx={{
              transition: (theme) => theme.transitions.create('opacity', { duration: 300 }),
              opacity: 1,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default IndustriesInfo;
