import React from 'react';

import Box from 'components/ui/Box';
import Typography from 'components/ui/Typography';
import Icon from 'components/ui/Icon';
import { MAX_WIDTH_CONTENT, OUR_VALUES } from '@constants';

import { HeaderSection } from 'components/common/ContentHeaderSection';
import { ModalContactFormWrapper } from 'components/common/ContactUs';

const OurValueBody = () => {
  return (
    <Box display={{ laptop: 'flex' }} mx="auto" width={1} maxWidth={MAX_WIDTH_CONTENT}>
      <Box display="flex" flexDirection="column" gap={6.25}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          maxWidth={680}
          gap={4}
          textAlign="center"
          mx="auto"
        >
          <HeaderSection
            title="A compass that guides&nbsp;every decision"
            subTitle="Our values"
            titleProps={{ sx: { color: 'secondary.main' } }}
          />
          <Typography color="white.light">
            The exceptional professional journey isn't just about guiding the ship, it's about
            setting sail with purpose and passion. Our value book isn't just a document &
            meaningless words, it's a compass that guides every decision, every interaction and
            every innovation that propels us forward. It was created by people who have defined
            below what they feel, respect and represent.
          </Typography>
        </Box>

        <Box display="flex" flexWrap="wrap" gap={3} width={1} mx="auto">
          {OUR_VALUES.map((item) => (
            <Box
              key={item.id}
              component="article"
              width={{ mobile: 1, tablet: 'calc(50% - 0.75rem)', laptop: 'calc(33.333% - 1rem)' }}
              backgroundColor="secondary.contrastText"
              display="flex"
              flexDirection="column"
              gap={1}
              borderRadius="8px"
              position="relative"
              px={{ mobile: 2, tablet: 3, laptop: 4 }}
              pt={{ mobile: 2, tablet: 3, laptop: 4 }}
              pb={{ mobile: 2, tablet: 3, laptop: 10 }}
            >
              <Typography
                variant="h3"
                fontSize={24}
                lineHeight="32px"
                color="secondary.main"
                extraBold
              >
                {item.title}
              </Typography>
              <Typography
                color="white.light"
                pb={1.5}
                pt={0.5}
                px={0}
                sx={{
                  '& span': { display: 'block', padding: '0.75rem 0' },
                }}
              >
                {item.text}
              </Typography>

              <Icon
                name={item.iconName}
                position={{ laptop: 'absolute' }}
                bottom={30}
                width="fit-content"
                fontSize={55}
                height={55}
                sx={{
                  transition: (theme) => theme.transitions.create('opacity', { duration: 300 }),
                  opacity: 1,
                }}
              />
            </Box>
          ))}
        </Box>

        <Box textAlign="center">
          <ModalContactFormWrapper buttonText="Navigate with us" />
        </Box>
      </Box>
    </Box>
  );
};

export default OurValueBody;
