import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from 'components/ui/Typography';
import Checkbox from 'components/ui/Checkbox';
import Box from 'components/ui/Box';
import Collapse from 'components/ui/Collapse';
import { ROUTES } from 'routes';

const AgreePrivacyPolicy = ({ control, name, error }) => {
  return (
    <Box>
      <Typography
        color="text.secondary"
        semiBold
        sx={{
          '& a': { fontFamily: 'inherit', color: 'primary.main' },
          '& a:hover': { color: 'primary.hover' },
        }}
      >
        <Controller
          control={control}
          name={name}
          defaultValue={false}
          render={({ field: { onChange, value, ref } }) => (
            <Checkbox ref={ref} checked={value} sx={{ mr: 1 }} onChange={onChange} />
          )}
        />
        You agree to our friendly&nbsp;
        <Link to={ROUTES.privacy}>privacy policy</Link>.
      </Typography>
      <Collapse in={Boolean(error?.message)}>
        <Typography mt={0.75} color="red.main" fontSize={14} lineHeight="20px">
          This field is required
        </Typography>
      </Collapse>
    </Box>
  );
};

export default AgreePrivacyPolicy;

AgreePrivacyPolicy.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  error: PropTypes.object,
};
